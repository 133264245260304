import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ConnectivityService } from '../../settings/services/connectivity.service';
import { LoginService } from '../login/login.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  valForm: FormGroup;
  toaster: { type: string; title: string; text: string };
  token: any;
  regexp;
  visitedverifyflag: boolean;
  verifyFlag: boolean;
  numberflag: boolean;
  lowerflag: boolean;
  upperflag: boolean;
  specialcharflag: boolean;
  lengthflag: boolean;
  visitedflag: boolean;
  verifyHide = true;
  newHide = true;
  currHide = true;
  roleOfUser: any;
  constructor(
    private router: Router,
    private fb: FormBuilder,
    private _snackBar: MatSnackBar,
    private _toaster: ToastrService,
    private _loginService: LoginService,
    private _route: ActivatedRoute,
    private cs: ConnectivityService
  ) {
    this.valForm = fb.group({
      newPassword: [null, [Validators.required, Validators.minLength(8)]],
      verifyPassword: [null, Validators.required],
    });
  }

  ngOnInit(): void {
    this.visitedflag = false;
    this.numberflag = false;
    this.lowerflag = false;
    this.upperflag = false;
    this.specialcharflag = false;
    this.lengthflag = false;
    this.visitedverifyflag = false;
    this.roleOfUser = 'operator';
    this._route.params.subscribe((param) => {
      const resetParamsData: any = JSON.parse(atob(param['token']));
      this.token = resetParamsData.token;
      this.roleOfUser = resetParamsData.role;
      if (this.roleOfUser == 'operator') {
        this.valForm.get('newPassword').clearValidators();
        this.valForm.get('newPassword').setValidators(Validators.required);
      } else {
        this.valForm.get('newPassword').clearValidators();
      }
    });
  }
  submitForm($ev, value: any) {
    $ev.preventDefault();
    for (let c in this.valForm.controls) {
      this.valForm.controls[c].markAsTouched();
    }
    if (this.valForm.valid) {
      this.resetpass(value);
    } else {
      this._snackBar.open(
        'New password and verify password is invalid.',
        'Dismiss',
        {
          duration: 3000,
        }
      );
    }
  }

  resetpass(value) {
    this.toaster = {
      type: 'success',
      title: 'Title',
      text: 'Message',
    };
    this._loginService.resetpass(value, this.token).subscribe(
      (data: any) => {
        this.router.navigate(['./page/password/success']);
        this.cs.emit('logout', window['user']);
      },
      (err) => {
        // console.log(err);
        this._snackBar.open(err.error.message + '.', 'Dismiss', {
          duration: 3000,
        });
      }
    );
  }

  RegexCheck() {
    this.numberflag = false;
    this.lowerflag = false;
    this.upperflag = false;
    this.specialcharflag = false;
    this.lengthflag = false;
    this.visitedflag = true;
    var trigger = 'abcd12',
      regexp = new RegExp('.*[0-9]+.*');
    this.numberflag = regexp.test(this.valForm.value.newPassword);

    (regexp = new RegExp('.*[a-z]+.*')),
      (this.lowerflag = regexp.test(this.valForm.value.newPassword));

    (regexp = new RegExp('.*[A-Z]+.*')),
      (this.upperflag = regexp.test(this.valForm.value.newPassword));

    (regexp = new RegExp('.*[@#$%^&+=*!]+.*')),
      (this.specialcharflag = regexp.test(this.valForm.value.newPassword));
  }
  PasswordMatch() {
    if (
      !this.valForm.value.verifyPassword ||
      this.valForm.value.verifyPassword != this.valForm.value.newPassword
    ) {
      this.verifyFlag = true;
    } else {
      this.verifyFlag = false;
    }
    return this.verifyFlag;
  }
}
