<div class="mat-typography">
  <div class="col-md-4 offset-md-4">
    <div style="text-align: center">
      <img
        class="img-fluid"
        src="../../../../assets/img/logo2.svg"
        alt="App Logo"
      />
    </div>
    <mat-horizontal-stepper linear #stepper labelPosition="bottom">
      <mat-step [stepControl]="firstFormGroup">
        <form [formGroup]="firstFormGroup">
          <div class="textSignin">
            <h5>Welcome! First things first...</h5>
            <h2>You can always change them later.</h2>
          </div>
          <div class="col-md-12 groupp">
            <mat-form-field appearance="outline" style="display: block">
              <mat-label>Full Name</mat-label>
              <input
                matInput
                placeholder="Last name, First name"
                oninput="this.value = this.value.replace(/[^A-Za-z0-9\S ]|^ /g,'')"
                formControlName="firstname"
                required
              />
              <mat-error
                *ngIf="
                  firstFormGroup.controls['firstname'].hasError('required') &&
                  (firstFormGroup.controls['firstname'].dirty ||
                    firstFormGroup.controls['firstname'].touched)
                "
              >
                Full name is required.
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-12 groupp">
            <mat-form-field appearance="outline" style="display: block">
              <mat-label>Display Name</mat-label>
              <input
                matInput
                placeholder="Last name, First name"
                oninput="this.value = this.value.replace(/[^A-Za-z0-9\S ]|^ /g,'')"
                formControlName="displayname"
                required
              />
              <mat-error
                *ngIf="
                  firstFormGroup.controls['displayname'].hasError('required') &&
                  (firstFormGroup.controls['displayname'].dirty ||
                    firstFormGroup.controls['displayname'].touched)
                "
              >
                Display name is required.
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-12">
            <button
              mat-raised-button
              class="btn btn-primary btn-lg"
              type="submit"
              style="width: 100%; margin-top: 8px"
              matStepperNext
            >
              Next
            </button>
          </div>
        </form>
      </mat-step>
      <mat-step [stepControl]="secondFormGroup">
        <form [formGroup]="secondFormGroup">
          <div class="textSignin">
            <h5>Let set up home for all your work.</h5>
            <h2>You can always create another workspace later.</h2>
          </div>
          <div class="col-md-12 groupp">
            <mat-form-field appearance="outline" style="display: block">
              <mat-label>Workspace Name</mat-label>
              <input
                matInput
                placeholder="Last name, First name"
                oninput="this.value = this.value.replace(/[^A-Za-z0-9\S ]|^ /g,'')"
                formControlName="workspacename"
                required
              />
              <mat-error
                *ngIf="
                  secondFormGroup.controls['workspaceurl'].hasError(
                    'required'
                  ) &&
                  (secondFormGroup.controls['workspaceurl'].dirty ||
                    secondFormGroup.controls['workspaceurl'].touched)
                "
              >
                Workspace name is required.
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-12 groupp">
            <mat-form-field appearance="outline" style="display: block">
              <mat-label>Workspace Url</mat-label>
              <input
                matInput
                placeholder="Last name, First name"
                formControlName="workspaceurl"
                required
              />
              <mat-error
                *ngIf="
                  secondFormGroup.controls['workspaceurl'].hasError(
                    'required'
                  ) &&
                  (secondFormGroup.controls['workspaceurl'].dirty ||
                    secondFormGroup.controls['workspaceurl'].touched)
                "
              >
                Workspace url is required.
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-12">
            <button
              mat-raised-button
              class="btn btn-primary btn-lg"
              type="submit"
              style="width: 100%; margin-top: 8px"
              matStepperNext
            >
              Next
            </button>
          </div>
        </form>
      </mat-step>
      <mat-step [stepControl]="thirdFormGroup">
        <form [formGroup]="thirdFormGroup">
          <div class="textSignin">
            <h5>How you are planning to use IoTsense?</h5>
            <h2 style="margin-bottom: 20px !important">
              We'll streamline your setup experience accordingly.
            </h2>
          </div>
          <div class="col-md-12 radio-groupp">
            <label class="level">
              <input type="radio" name="radio" />
              <div class="back-end box">
                <span>
                  <h2>For myself</h2>
                  Write better. Think more clearly. Stay organized
                </span>
              </div>
            </label>
            <label class="level">
              <input type="radio" name="radio" checked />
              <div class="front-end box">
                <span>
                  <h2>With my team</h2>
                  Wikis, docs, task and projects all in one place
                </span>
              </div>
            </label>
            <label class="level">
              <input type="radio" name="radio" checked />
              <div class="front-end box">
                <span>
                  <h2>With team</h2>
                  Wikis, docs, task and projects all in one place
                </span>
              </div>
            </label>
          </div>
          <div class="col-md-12">
            <button
              mat-raised-button
              class="btn btn-primary btn-lg"
              type="submit"
              style="width: 100%"
              matStepperNext
            >
              Next
            </button>
          </div>
        </form>
      </mat-step>
      <mat-step>
        <div style="margin-top: 20px; color: #28519d; text-align: center">
          <mat-icon style="font-size: 50px">check_circle</mat-icon>
        </div>
        <div class="textSignin" style="margin-top: 20px">
          <h5>Congratulation, Xyz!</h5>
          <h2>
            You have completed registration, you can start using the IoTsense.
          </h2>
        </div>
        <div class="col-md-12">
          <button
            mat-raised-button
            class="btn btn-primary btn-lg"
            type="submit"
            style="width: 100%"
            matStepperNext
          >
            Next
          </button>
        </div>
      </mat-step>
    </mat-horizontal-stepper>
  </div>
</div>
