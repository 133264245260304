import { Component, OnInit, OnDestroy, ElementRef } from '@angular/core';
import { SettingsService } from '../../core/settings/settings.service';
import { ThemesService } from '../../core/themes/themes.service';
import { TranslatorService } from '../../core/translator/translator.service';
import { NotificationServiceService } from '../notification-service.service';
import { NotificationAPIService } from '../notifications/services/notification-api.service';
import { ConnectivityService } from 'src/app/routes/settings/services/connectivity.service';
import { TriggerService } from 'src/app/routes/smart-action/services/trigger.service';
import { ToastrService } from 'ngx-toastr';
import { LoaderService } from '../loader/loader.service';
import { SharedService } from 'src/app/shared.service';
import { AlertConfigurationService } from 'src/app/routes/settings/services/alert-configuration.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent implements OnInit, OnDestroy {
  currentTheme: any;
  selectedLanguage: string;
  notifications: any = [];
  panelOpenState = false;
  triggerList: any = [];
  toaster: any;
  loaderService = new LoaderService();
  notificationSubs = [];

  constructor(
    public settings: SettingsService,
    public themes: ThemesService,
    public translator: TranslatorService,
    public elem: ElementRef,
    public notificationServiceService: NotificationServiceService,
    public notificationAPI: NotificationAPIService,

    private ws: ConnectivityService,
    private ts: TriggerService,
    private _toaster: ToastrService,
    private ss: SharedService,
    private alertservice: AlertConfigurationService
  ) {
    this.currentTheme = themes.getDefaultTheme();
    this.selectedLanguage = this.getLangs()[0].code;
  }

  ngOnInit() {
    this.toaster = {
      type: 'success',
      title: 'IoTSense',
      text: 'Message',
    };
    this.anyClickClose();
    var root = this;
    const logoutsub = root.ws.listen('logout').subscribe((logoutRes: any) => {
      if (logoutRes.user._id == window['user']._id) root.logout();
    });
    /* Listening to socket based Banner notifications */
    const newNotifysub = root.ws
      .listen('Banner')
      .subscribe((notifyTrigger: any) => {
        if (window['user']._id == notifyTrigger.owner) {
          /* Updating Banner details */
          root.alertservice.readAlertConfig().subscribe((alerConfig: any) => {
            if (alerConfig.Admin == true) {
              let bannerPaylod = {
                type: notifyTrigger.action.alerttype,
                msg: notifyTrigger.action.Iotmessage,
                data: notifyTrigger,
              };
              root.ss.updateBanner(bannerPaylod);
              /* Calling show banner with configured audio as parameter */
              this.ss.showNotificationBanner(
                alerConfig[notifyTrigger.action.alerttype],
                notifyTrigger.action.alerttype,
                bannerPaylod,
                notifyTrigger
              );
            }
          });
        }
      });
    /* Getting new notifications from socket */
    const nmSub = this.ws.listen('newnotifications').subscribe((data: any) => {
      if (data.notifications.length == 0) {
        this.notifications = [...data.notifications, ...this.notifications];
      } else {
        if (window['user']._id == data.notifications[0].userid)
          this.notifications = data.notifications || [];
      }
      this.notifications.reverse();
      this.notifications =
        this.notifications.length > 100
          ? this.notifications.slice(0, 100)
          : this.notifications;
      this.notificationServiceService.data = this.notifications;
    });
    this.notificationAPI
      .getUnreadNotifications()
      .subscribe((newnotifications: any) => {
        this.notifications = newnotifications || [];
        this.notifications.reverse();
        this.notifications =
          this.notifications.length > 100
            ? this.notifications.slice(0, 100)
            : this.notifications;

        this.notificationServiceService.data = this.notifications;
        if (!window['user'].is_Invited) {
          /* For getting reliable stream of notifications */
          // setInterval(() => {
          var time =
            this.notifications.length > 0
              ? new Date(this.notifications[0].timestamp).getTime()
              : '';
          /*Emitting socket event for "newnotifications"  */
          this.ws.emit('newnotifications', window['user']);
          // }, 10000);
        }
      });
    this.notificationSubs.push(newNotifysub);
    this.notificationSubs.push(logoutsub);
    this.notificationSubs.push(nmSub);
  }

  clearRecentNotifications() {
    this.notifications = [];
    this.notificationServiceService.data = this.notifications;

    this.notificationAPI
      .clearNotifications(this.notifications)
      .subscribe((data: any) => {
        this.notifications = [];
        this.notificationServiceService.data = this.notifications;
      });
  }

  setTheme() {
    this.themes.setTheme(this.currentTheme);
  }

  getLangs() {
    return this.translator.getAvailableLanguages();
  }

  setLang(value) {
    this.translator.useLanguage(value);
  }

  anyClickClose() {
    document.addEventListener('click', this.checkCloseOffsidebar, false);
  }

  checkCloseOffsidebar = (e) => {
    const contains =
      this.elem.nativeElement !== e.target &&
      this.elem.nativeElement.contains(e.target);
    if (!contains) {
      this.settings.setLayoutSetting('openNotifications', false);
    }
  };

  ngOnDestroy() {
    document.removeEventListener('click', this.checkCloseOffsidebar);
    this.notificationSubs.forEach((sub) => sub.unsubscribe());
  }
  logout() {
    this._toaster.info('This session has expired, login again.', 'IoTSense', {
      timeOut: 3000,
    });
    setTimeout(() => {
      window.location.reload();
    }, 3000);
  }
}
