import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/observable/forkJoin';
import { environment } from "../../../../environments/environment";
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TriggerService {

  private url = "";
  constructor(private http: HttpClient) {
    if (environment.apiUrl) {
      this.url = environment.apiUrl
    };
   }

   getTriggers() {
    return this.http.get(this.url + '/api/triggers', { withCredentials: true }).pipe(map(list => {
      return list;
    }));
  }
  getSyncOptions() {
    return this.http.get(this.url + '/api/sync/options/', { withCredentials: true }).pipe(map(list => {
      return list;
    }));
  }

  getSyncOptionsbyid(data) {
    return this.http.get(this.url + '/api/sync/options/'+data, { withCredentials: true }).pipe(map(list => {
      return list;
    }));
  }
  

  getTriggerView(triggerId){
    return this.http.get(this.url + '/api/triggers/'+triggerId, { withCredentials: true }).pipe(map(list => {
      return list;
    }));
  }
  removetrigger(triggerID){
    return this.http.delete(this.url + '/api/triggers/'+triggerID, { withCredentials: true }).pipe(map(list => {
      return list;
    }));
  }

  updateTrigger(data,id){  
    return this.http.put(this.url +  '/api/triggers/'+id,data, { withCredentials: true }).pipe(map(list => {
      return list;
    }));
  }
  createTrigger(data){  
    return this.http.post(this.url +  '/api/triggers',data, { withCredentials: true }).pipe(map(list => {
      return list;
    }));
  }

}
