// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: '',
  appName: 'IoTSense',
  appVersion: '4.0.0',
  appType: 'saas',
  // recaptchaKey: '6Lc8P64bAAAAAIhNoTPhpkc0tq3PiQDNg2iuKd9N',
  rzpKey: 'rzp_test_prjJk3Mphqtfeo',
  isUpgradeImmediately: false,
  isCancelImmediately: false,
  showOnlyFreeTrial: true,
  getYear: () => {
    return new Date().getFullYear();
  },
};

// http://localhost:3000
export const hierarchy = {
  //Add plural forms of the name, eg. 'devices' and not 'device'
  level0: 'Things',
  level1: 'Machines',
  level2: 'Machine Groups',
  level3: 'Plants',
};
export const ENV = {
  DEV_KEY: 'rzp_test_prjJk3Mphqtfeo',
  PROD_KEY: 'rzp_test_prjJk3Mphqtfeo',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
