import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EmailLinkResendService {

  header = new Headers({ 'Content-Type': 'application/json' });
  private url = "";

  constructor(private http: HttpClient) {
    if (environment.apiUrl) {
      this.url = environment.apiUrl;
    }
  }

  resendEmail(email) {
    return this.http.post(this.url + '/api/auth/resendEmail', email).pipe(map(result => {
      return result;
    }));
  }

}
