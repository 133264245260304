import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/observable/forkJoin';
import { environment } from '../../../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class NotificationAPIService {
  private url = '';
  constructor(private http: HttpClient) {
    if (environment.apiUrl) {
      this.url = environment.apiUrl;
    }
  }

  getUnreadNotifications() {
    return this.http
      .get(this.url + '/api/notifications', { withCredentials: true })
      .pipe(
        map((notifications) => {
          return notifications;
        })
      );
  }

  getNewNotification(notifications) {
    var time =
      notifications.length > 0
        ? new Date(notifications[0].timestamp).getTime()
        : '';
    var str = '/api/notifications?after=' + time;
    return this.http.get(this.url + str, { withCredentials: true }).pipe(
      map((notification) => {
        return notification;
      })
    );
  }

  clearNotifications(notifications) {
    var str =
      '/api/notifications/clear?afterDate=' +
      new Date(notifications[0]?.timestamp || new Date()).getTime();
    return this.http.get(this.url + str, { withCredentials: true }).pipe(
      map((notifications) => {
        return notifications;
      })
    );
  }
}
