import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { map } from 'rxjs/operators';
import * as CryptoJS from 'crypto-js';


@Injectable({
  providedIn: 'root',
})
export class LoginService {
  header = new Headers({ 'Content-Type': 'application/json' });
  private url = '';
  private ENCRYPTION_KEY = "wj_iotSense@1234";

  constructor(private http: HttpClient) {
    if (environment.apiUrl) {
      this.url = environment.apiUrl;
    }
  }

  doLogin(value) {
    //let url = '/api/auth/signin';
    //return this.http.post(url,value,options) // comment to run independently
    return this.http.post(this.url + '/api/auth/signin', value).pipe(
      map((user) => {
        // login successful if there's a jwt token in the response
        return user;
      })
    );
  }
  signout() {
    //let url = '/api/auth/signin';
    //return this.http.post(url,value,options) // comment to run independently
    return this.http
      .get(this.url + '/api/auth/signout', { withCredentials: true })
      .pipe(
        map((user) => {
          // login successful if there's a jwt token in the response
          return user;
        })
      );
  }
  forgetpass(value) {
    return this.http.post(this.url + '/api/auth/forgot', value).pipe(
      map((user) => {
        return user;
      })
    );
  }
  resetpass(value, token) {
    return this.http.post(this.url + '/api/auth/reset/' + token, value).pipe(
      map((user) => {
        return user;
      })
    );
  }

  getData() {
    return this.http.get(this.url + '/api/things').pipe(
      map((data) => {
        return data;
      })
    );
  }

  getUserDetails() {
    return this.http
      .get(this.url + '/api/users/me', { withCredentials: true })
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
  getAnomalyNotifications() {
    return this.http
      .get(this.url + '/api/analytics/aireports/anamolylogs/notifications', {
        withCredentials: true,
      })
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
  getMapNotifications() {
    return this.http
      .get(this.url + '/api/analytics/maps/notifications', {
        withCredentials: true,
      })
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
  getIDNotifications() {
    return this.http
      .get(this.url + '/api/analytics/id/notifications', {
        withCredentials: true,
      })
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
  clearMapNotifications() {
    return this.http
      .delete(this.url + '/api/analytics/maps/notifications/clear', {
        withCredentials: true,
      })
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
  clearIDNotifications() {
    return this.http
      .delete(this.url + '/api/analytics/id/notifications/clear', {
        withCredentials: true,
      })
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
  getDashboardsDetails(dashboardsId) {
    return this.http
      .get(this.url + '/api/analytics/dashboards/' + dashboardsId, {
        withCredentials: true,
      })
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
  upadteuserDetails(data, userId) {
    return this.http
      .put(this.url + '/api/users/role/' + userId, data, {
        withCredentials: true,
      })
      .pipe(
        map((list) => {
          return list;
        })
      );
  }

  deleteUsersDashboardId(dashboardId: any) {
    return this.http
      .post(this.url + '/api/users/deleteUsersDashboardId/' + dashboardId, {})
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  getAllUsers() {
    return this.http.get(this.url + '/api/users/all').pipe(
      map((data) => {
        return data;
      })
    );
  }

  getAllUsersByDashboardId(dashboardId: any) {
    return this.http
      .get(this.url + '/api/users/alldashboardUser/' + dashboardId)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  updateDashboardIds(dashboardId, value: any) {
    return this.http
      .post(
        this.url + '/api/users/updateUsersDashboardId/' + dashboardId,
        value
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
  getUserSubscriptionInfo(userId) {
    return this.http
      .get(this.url + '/api/user/subscriptioninfo/' + userId)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
  // encrypt login user credentials
  encryptData(inputString) {

    let _key = CryptoJS.enc.Utf8.parse(this.ENCRYPTION_KEY);
    let _iv = CryptoJS.enc.Utf8.parse(this.ENCRYPTION_KEY);

    let encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(inputString), _key, {
      keySize: 16,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    return encrypted.toString();
  }



}
