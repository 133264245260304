import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/observable/forkJoin';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class UsersettingsService {
  myMethod$: Observable<any>;
  private myMethodSubject = new Subject<any>();
  private url = '';
  constructor(private http: HttpClient) {
    if (environment.apiUrl) {
      this.url = environment.apiUrl;
    }

    this.myMethod$ = this.myMethodSubject.asObservable();
  }

  myMethod(data) {
    this.myMethodSubject.next(data);
  }

  updateprofile(user) {
    return this.http
      .put(this.url + '/api/users', user, { withCredentials: true })
      .pipe(
        map((list) => {
          return list;
        })
      );
  }
  changeCredentials(data) {
    return this.http
      .post(this.url + '/api/users/password', data, { withCredentials: true })
      .pipe(
        map((list) => {
          return list;
        })
      );
  }
}
