<div class="mat-typography">
  <div class="col-md-6 offset-md-3">
    <div style="text-align: center">
      <img
        class="img-fluid"
        src="../../../../assets/img/logo2.svg"
        alt="App Logo"
      />
      <h4>Please fill the form to create an account</h4>
    </div>
    <form
      [formGroup]="registrationForm"
      novalidate
      (ngSubmit)="submitForm()"
      autocomplete="off"
    >
      <div class="col-md-12 groupp">
        <div class="mda-row row">
          <div class="col-md-6">
            <mat-form-field appearance="outline" style="display: block">
              <mat-label>First Name</mat-label>
              <input
                matInput
                placeholder="First name"
                oninput="this.value = this.value.replace(/[^A-Za-z0-9\S ]|^ /g,'')"
                formControlName="firstname"
                maxlength="50"
                required
              />
              <mat-error
                *ngFor="let validation of account_validation_messages.firstname"
              >
                <mat-error
                  *ngIf="
                    registrationForm
                      .get('firstname')
                      .hasError(validation.type) &&
                    registrationForm.get('firstname').invalid &&
                    registrationForm.get('firstname').touched
                  "
                >
                  {{ validation.message }}
                </mat-error>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <mat-form-field appearance="outline" style="display: block">
              <mat-label>Last Name</mat-label>
              <input
                matInput
                placeholder="Last name"
                oninput="this.value = this.value.replace(/[^A-Za-z0-9\S ]|^ /g,'')"
                formControlName="lastname"
                maxlength="50"
                required
              />
              <mat-error
                *ngFor="let validation of account_validation_messages.lastname"
              >
                <mat-error
                  *ngIf="
                    registrationForm
                      .get('lastname')
                      .hasError(validation.type) &&
                    registrationForm.get('lastname').invalid &&
                    registrationForm.get('lastname').touched
                  "
                >
                  {{ validation.message }}
                </mat-error>
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="col-md-12 groupp">
        <div class="mda-row row">
          <div class="col-md-6 pt-2">
            <mat-form-field appearance="outline" style="display: block">
              <mat-label>Username</mat-label>
              <input
                matInput
                placeholder="Username"
                oninput="this.value = this.value.replace(/[^A-Za-z0-9\S ]|^ /g,'')"
                formControlName="username"
                maxlength="25"
                required
              />
              <mat-error
                *ngFor="let validation of account_validation_messages.username"
              >
                <mat-error
                  class="responsive-username"
                  *ngIf="
                    registrationForm
                      .get('username')
                      .hasError(validation.type) &&
                    registrationForm.get('username').invalid &&
                    registrationForm.get('username').touched
                  "
                >
                  {{ validation.message }}
                </mat-error>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-6 col-sm pt-2">
            <mat-form-field appearance="outline" style="display: block">
              <mat-label>Email</mat-label>
              <input
                matInput
                placeholder="Enter email"
                oninput="this.value = this.value.replace(/[^A-Za-z0-9\S ]|^ /g,'')"
                formControlName="email"
                required
              />
              <mat-error
                *ngFor="let validation of account_validation_messages.email"
              >
                <mat-error
                  *ngIf="
                    registrationForm.get('email').hasError(validation.type) &&
                    registrationForm.get('email').invalid &&
                    registrationForm.get('email').touched
                  "
                >
                  {{ validation.message }}
                </mat-error>
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div [formGroup]="passwordForm">
        <div class="col-md-12 pt-3 groupp">
          <div class="mda-row row">
            <div class="col-md-6">
              <mat-form-field appearance="outline" style="display: block">
                <mat-label>Password</mat-label>
                <input
                  matInput
                  [type]="hide ? 'password' : 'text'"
                  placeholder="Enter password"
                  maxlength="40"
                  formControlName="password"
                  required
                />
                <mat-icon id="passCursor" matSuffix (click)="hide = !hide"
                  >{{ hide ? "visibility_off" : "visibility" }}
                </mat-icon>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field appearance="outline" style="display: block">
                <mat-label>Confirm Password</mat-label>
                <input
                  matInput
                  [type]="confirmaPasswordHide ? 'password' : 'text'"
                  placeholder="Enter password"
                  formControlName="confirmPassword"
                  required
                />
                <mat-icon
                  id="passCursor"
                  matSuffix
                  (click)="confirmaPasswordHide = !confirmaPasswordHide"
                >
                  {{
                    confirmaPasswordHide ? "visibility_off" : "visibility"
                  }}</mat-icon
                >
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <div class="passwordWidgets mda-row" style="text-align: center">
            <div
              class="col-md-3"
              [ngClass]="
                passwordForm.controls['password'].hasError('required') ||
                passwordForm.controls['password'].hasError('minlength')
                  ? passwordForm.get('password').untouched
                    ? 'widget init'
                    : 'widget error'
                  : 'widget success'
              "
            >
              8 Characters
            </div>

            <div
              class="col-md-2"
              [ngClass]="
                passwordForm.controls['password'].hasError('required') ||
                passwordForm.controls['password'].hasError(
                  'hasSpecialCharacters'
                )
                  ? passwordForm.get('password').untouched
                    ? 'widget init'
                    : 'widget error'
                  : 'widget success'
              "
            >
              1 Special
            </div>

            <div
              class="col-md-3"
              [ngClass]="
                passwordForm.controls['password'].hasError('required') ||
                passwordForm.controls['password'].hasError('hasCapitalCase')
                  ? passwordForm.get('password').untouched
                    ? 'widget init'
                    : 'widget error'
                  : 'widget success'
              "
            >
              1 Uppercase
            </div>

            <div
              class="col-md-3"
              [ngClass]="
                passwordForm.controls['password'].hasError('required') ||
                passwordForm.controls['password'].hasError('hasNumber')
                  ? passwordForm.get('password').untouched
                    ? 'widget init'
                    : 'widget error'
                  : 'widget success'
              "
            >
              1 Numeric
            </div>
          </div>
        </div>
        <div
          class="col-md-10 offset-md-1 groupp"
          *ngIf="passwordForm.get('password').touched && passwordForm.invalid"
        >
          <div class="row" style="font-size: 75%">
            <mat-error
              *ngIf="
                passwordForm.controls['password'].hasError('required') ||
                passwordForm.controls['password'].hasError('minlength')
              "
            >
              Must be at least {{ paswdProfile.minChar }} characters.
              <span>&nbsp;&nbsp;</span>
            </mat-error>
            <mat-error
              *ngIf="
                passwordForm.controls['password'].hasError('required') ||
                passwordForm.controls['password'].hasError(
                  'hasSpecialCharacters'
                )
              "
            >
              Must contain at least {{ paswdProfile.specialChar }} Special
              Character.<span>&nbsp;&nbsp;</span>
            </mat-error>
            <mat-error
              *ngIf="
                passwordForm.controls['password'].hasError('required') ||
                passwordForm.controls['password'].hasError('hasCapitalCase')
              "
            >
              Must contain at least {{ paswdProfile.upperCase }} in Capital
              Case.<span>&nbsp;</span>
            </mat-error>
            <mat-error
              *ngIf="
                passwordForm.controls['password'].hasError('required') ||
                passwordForm.controls['password'].hasError('hasNumber')
              "
            >
              Must contain at least {{ paswdProfile.numbers }} number.<span
                >&nbsp;&nbsp;</span
              >
            </mat-error>
            <mat-error
              *ngIf="
                passwordForm.invalid &&
                passwordForm.get('confirmPassword').touched
              "
            >
              Password do not match.
            </mat-error>
          </div>
        </div>
      </div>
      <div class="col-md-12 pt-1 groupp">
        <mat-form-field appearance="outline" style="display: block">
          <input
            matInput
            ng2TelInput
            (countryChange)="onCountryChange($event)"
            [ng2TelInputOptions]="{ initialCountry: 'in' }"
            placeholder="Phone"
            (keypress)="numericOnly($event)"
            maxlength="10"
            formControlName="phoneNumber"
            required
          />
          <mat-error
            *ngIf="
              registrationForm.get('phoneNumber').invalid &&
              registrationForm.get('phoneNumber').touched
            "
          >
            <mat-error *ngIf="f.phoneNumber.errors.required"
              >Phone is required</mat-error
            >
            <mat-error *ngIf="f.phoneNumber.errors.minlength">
              Phone must be at least 10 numbers
            </mat-error>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-12">
        <button
          [disabled]="passwordForm.invalid || registrationForm.invalid"
          mat-raised-button
          class="btn btn-primary btn-lg"
          type="submit"
          style="
            width: 100%;
            margin-top: 8px;
            letter-spacing: 0.3px;
            margin-bottom: 10px;
          "
        >
          Sign Up
        </button>
      </div>
    </form>
    <div class="col-md-12 pb-4">
      Already a user ? <a routerLink="/login">Login</a>
    </div>
  </div>
</div>
