import { Component, OnInit } from '@angular/core';
import { SettingsService } from '../../../core/settings/settings.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CustomValidators } from 'ngx-custom-validators';
import { LoginService } from '../login/login.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-recover',
  templateUrl: './recover.component.html',
  styleUrls: ['./recover.component.scss'],
})
export class RecoverComponent implements OnInit {
  valForm: FormGroup;
  toaster: any;
  constructor(
    public settings: SettingsService,
    private _snackBar: MatSnackBar,
    private router: Router,
    fb: FormBuilder,
    private _loginService: LoginService,
    private _toaster: ToastrService
  ) {
    this.valForm = fb.group({
      username: [null, Validators.required],
    });
  }
  changepass(val) {
    this.toaster = {
      type: 'success',
      title: 'Title',
      text: 'Message',
    };
    this._loginService.forgetpass(val).subscribe(
      (data) => {
        this.router.navigate(['login']);
      },
      (error) => {
        this._snackBar.open(error.error.message + '.', 'Dismiss', {
          duration: 100000,
        });
      }
    );
  }
  submitForm($ev, value: any) {
    $ev.preventDefault();
    for (let c in this.valForm.controls) {
      this.valForm.controls[c].markAsTouched();
    }
    if (this.valForm.valid) {
      this.changepass(value);
    }
  }
  // onBlur(e){
  //     if(e.target.value==''){
  //       this._snackBar.open("Username is required.", 'Dismiss', {
  //           duration: 3000
  //         });
  //     }
  //   }
  ngOnInit() {}
}
