<div class="container divMain">
  <div class="loginBox">
    <div class="topMain">
      <img
        class="img-fluid"
        src="../../../../assets/img/logo2.svg"
        alt="App Logo"
      />
      <div class="textSignin">
        <h5>Restore your password</h5>
      </div>

      <div class="col-md-12">
        <form
          [formGroup]="valForm"
          role="form"
          name="ForgotpasswordForm"
          novalidate=""
          (submit)="submitForm($event, valForm.value)"
        >
          <div class="col-md-12">
            <mat-form-field
              appearance="outline"
              style="display: block; margin-bottom: 12px"
            >
              <mat-label>Username</mat-label>
              <input
                matInput
                id="exampleInputEmail1"
                type="text"
                name="username"
                placeholder="Username"
                autocomplete="off"
                required=""
                formControlName="username"
              />
              <mat-error *ngIf="valForm.get('username').hasError('required')"
                >Username is required</mat-error
              >
            </mat-form-field>
          </div>

          <div class="btnLoginbox">
            <button
              mat-raised-button
              class="btn btn-primary btn-lg"
              type="submit"
            >
              Submit
            </button>
            <div>
              <a href="/login"
                >Oh, I remember my password now. Take me back to login page.</a
              >
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
