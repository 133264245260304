<!-- START Sidebar (left)-->
<div class="aside-inner" [style.marginTop.px]="bannerInfo.flag ? 52 : -3">
  <nav
    class="sidebar"
    sidebar-anyclick-close=""
    [class.show-scrollbar]="settings.getLayoutSetting('asideScrollbar')"
  >
    <!-- START sidebar nav-->
    <ul class="sidebar-nav">
      <!-- START user info-->
      <!-- <li class="has-user-block">
                <app-userblock></app-userblock>
            </li> -->
      <!-- END user info-->

      <li
        *ngFor="let item of displaymenuItems; let i = index"
        [ngClass]="{ 'nav-heading': item.heading }"
        [routerLinkActive]="['active']"
        #refsidebar="routerLinkActive"
      >
        <!-- menu heading -->
        <!-- <span *ngIf="item.heading">{{(item.translate | translate) || item.text}}</span> -->
        <!-- external links -->
        <a
          href
          mat-ripple
          *ngIf="!item.heading && !item.submenu && item.elink"
          [attr.target]="item.target"
          [attr.href]="item.elink"
          title="{{ item.text }}"
        >
          <span
            class="float-right"
            *ngIf="item.alert"
            [ngClass]="item.label || 'badge badge-success'"
            >{{ item.alert }}</span
          >
          <em class="{{ item.icon }} bi-font-size" *ngIf="item.icon"></em>
          <span>{{ (item.translate | translate) || item.text }}</span>
        </a>
        <!-- single menu item -->
        <a
          href
          mat-ripple
          *ngIf="
            !item.heading &&
            !item.submenu &&
            !item.elink &&
            item.text == 'Analytics'
          "
          title="{{ item.text }}"
          (click)="checkanalytics()"
          (click)="toggleSubmenuClick($event)"
          (mouseenter)="toggleSubmenuHover($event)"
        >
          <span
            class="float-right"
            *ngIf="item.alert"
            [ngClass]="item.label || 'badge badge-success'"
            >{{ item.alert }}</span
          >
          <em class="{{ item.icon }} bi-font-size" *ngIf="item.icon"></em>
          <span>{{ (item.translate | translate) || item.text }}</span>
        </a>

        <!-- single menu with premium flag -->

        <a
          mat-ripple
          *ngIf="
            !item.heading &&
            !item.submenu &&
            !item.elink &&
            item.text != 'Analytics' &&
            item.is_premium
          "
          tooltip="{{ item.is_premium ? '' : item.text }}"
          [mdePopoverTriggerFor]="appPopover"
          mdePopoverTriggerOn="hover"
          (mouseover)="configurePremiumMsg(i)"
        >
          <span
            class="float-right"
            *ngIf="item.alert"
            [ngClass]="item.label || 'badge badge-success'"
            >{{ item.alert }}</span
          >
          <em
            class="{{
              item?.premiumMsg ? item.icon + ' no-cursor' : item.icon
            }} bi-font-size"
            *ngIf="item.icon"
            style="color: #3a393961"
          ></em>
          <span style="color: #3a393961; cursor: not-allowed !important"
            >{{ (item.translate | translate) || item.text }}
            <span style="cursor: not-allowed !important">
              <span
                style="
                  background-color: transparent;
                  font-weight: 500;
                  border-radius: 5px;
                  font-size: 70%;
                "
              >
                <!-- background-color: #07509D; -->
                <!-- <span style="padding: 2%;color: white;font-weight: bold;font-size: smaller;">
                  Premium
                </span> -->
                &nbsp;
                <i
                  class="fas fa-crown"
                  style="color: #07509d; font-size: 14px"
                ></i>

                <!-- <span style="padding: 2%;" data-toggle="tooltip" trigger="hover" triggers="mouseenter:mouseleave" placement="right"
               ngbPopover={{item.desc}} popoverTitle={{item.text}}>
                 UPGRADE
               </span>  -->
              </span>
            </span>
          </span>
        </a>
        <a
          mat-ripple
          *ngIf="
            !item.heading &&
            !item.submenu &&
            !item.elink &&
            item.text != 'Analytics' &&
            item.is_custom
          "
          tooltip="{{ item.is_custom ? '' : item.text }}"
          [mdePopoverTriggerFor]="appPopover"
          mdePopoverTriggerOn="hover"
          (mouseover)="configurePremiumMsg(i)"
        >
          <span
            class="float-right"
            *ngIf="item.alert"
            [ngClass]="item.label || 'badge badge-success'"
            >{{ item.alert }}</span
          >
          <em
            class="{{
              item?.premiumMsg ? item.icon + ' no-cursor' : item.icon
            }} bi-font-size"
            *ngIf="item.icon"
            style="color: #3a393961"
          ></em>
          <span style="color: #3a393961; cursor: not-allowed !important"
            >{{ (item.translate | translate) || item.text }}
          </span>
        </a>

        <!-- single menu without premium flag -->
        <a
          href
          mat-ripple
          *ngIf="
            !item.heading &&
            !item.submenu &&
            !item.elink &&
            item.text != 'Analytics' &&
            !item.is_premium &&
            !item.is_custom
          "
          [routerLink]="item.link"
          [attr.route]="item.link"
          title="{{ item.text }}"
          (click)="toggleSubmenuClick($event)"
          (mouseenter)="toggleSubmenuHover($event)"
        >
          <span
            class="float-right"
            *ngIf="item.alert"
            [ngClass]="item.label || 'badge badge-success'"
            >{{ item.alert }}</span
          >
          <em class="{{ item.icon }} bi-font-size" *ngIf="item.icon"></em>
          <span>{{ (item.translate | translate) || item.text }}</span>
        </a>
        <!-- has submenu with premium flag-->
        <a
          href
          mat-ripple
          [mdePopoverTriggerFor]="appPopover"
          mdePopoverTriggerOn="hover"
          (mouseover)="configurePremiumMsg(i)"
          *ngIf="!item.heading && item.submenu && item.is_premium"
          tooltip="{{ item.is_premium ? '' : item.text }}"
          style="cursor: not-allowed"
        >
          <span
            class="float-right"
            *ngIf="item.alert"
            [ngClass]="item.label || 'badge badge-success'"
            >{{ item.alert }}</span
          >
          <em
            class="{{
              item?.premiumMsg ? item.icon + ' no-cursor' : item.icon
            }} bi-font-size"
            *ngIf="item.icon"
            style="
              color: #3a393961;
              pointer-events: none !important;
              cursor: not-allowed !important;
            "
          ></em>
          <span
            style="
              color: #3a393961;
              cursor: not-allowed !important;
              pointer-events: none !important;
            "
            >{{ (item.translate | translate) || item.text }}
            <span
              style="
                cursor: not-allowed !important;
                pointer-events: none !important;
              "
            >
              <span
                style="
                  background-color: transparent;

                  font-weight: 500;
                  border-radius: 5px;
                  font-size: 70%;
                "
              >
                <!-- <span style="padding: 2%;color: white;font-weight: bold;font-size: smaller;">
                  Premium
                </span> -->
                &nbsp;<i
                  class="fas fa-crown"
                  style="color: #07509d; font-size: 14px"
                ></i>
                <!-- <span style="padding: 2%;" data-toggle="tooltip" trigger="hover" triggers="mouseenter:mouseleave" placement="right"
               ngbPopover={{item.desc}} popoverTitle={{item.text}}>
                 UPGRADE
               </span>       -->
              </span>
            </span>
          </span>
        </a>
        <a
          href
          mat-ripple
          [mdePopoverTriggerFor]="appPopover"
          mdePopoverTriggerOn="hover"
          (mouseover)="configurePremiumMsg(i)"
          *ngIf="!item.heading && item.submenu && item.is_custom"
          tooltip="{{ item.is_custom ? '' : item.text }}"
        >
          <span
            class="float-right"
            *ngIf="item.alert"
            [ngClass]="item.label || 'badge badge-success'"
            >{{ item.alert }}</span
          >
          <em
            class="{{
              item?.premiumMsg ? item.icon + ' no-cursor' : item.icon
            }} bi-font-size"
            *ngIf="item.icon"
            style="color: #3a393961"
          ></em>
          <span style="color: #3a393961; cursor: not-allowed !important"
            >{{ (item.translate | translate) || item.text }}
          </span>
        </a>

        <!-- has submenu without premium flag-->
        <a
          href
          mat-ripple
          *ngIf="
            !item.heading && item.submenu && !item.is_premium && !item.is_custom
          "
          title="{{ item.text }}"
          (click)="toggleSubmenuClick($event)"
          (mouseenter)="toggleSubmenuHover($event)"
        >
          <span
            class="float-right"
            *ngIf="item.alert"
            [ngClass]="item.label || 'badge badge-success'"
            >{{ item.alert }}</span
          >
          <em class="{{ item.icon }} bi-font-size" *ngIf="item.icon"></em>
          <span>{{ (item.translate | translate) || item.text }}</span>
        </a>
        <!-- SUBLEVEL -->
        <ul
          *ngIf="item.submenu"
          class="sidebar-nav sidebar-subnav"
          [routerLinkActive]="['opening']"
          [style.height]="refsidebar.isActive ? 'auto' : 0"
        >
          <li class="sidebar-subnav-header">
            {{ (item.translate | translate) || item.text }}
          </li>
          <li
            *ngFor="let subitem of item.submenu; let j = index"
            [routerLinkActive]="['active']"
          >
            <!-- sublevel: external links -->
            <a
              href
              mat-ripple
              *ngIf="!subitem.heading && !subitem.submenu && subitem.elink"
              [attr.target]="subitem.target"
              [attr.href]="subitem.elink"
              title="{{ subitem.text }}"
            >
              <span
                class="float-right"
                *ngIf="subitem.alert"
                [ngClass]="subitem.label || 'badge badge-success'"
                >{{ subitem.alert }}</span
              >
              <em class="{{ subitem.icon }}" *ngIf="subitem.icon"></em>
              <span>{{ (subitem.translate | translate) || subitem.text }}</span>
            </a>
            <!-- sublevel: single menu item with premium flag -->
            <a
              mat-ripple
              *ngIf="!subitem.submenu && !subitem.elink && subitem.is_premium"
              [class.no-cursor]="subitem.is_premium"
              (mouseover)="configurePremiumMsgSubmenu(i, j)"
              tooltip="{{ subitem.is_premium ? '' : subitem.text }}"
              [mdePopoverTriggerFor]="appPopover"
              mdePopoverTriggerOn="hover"
            >
              <span
                class="float-right"
                *ngIf="subitem.alert"
                [ngClass]="subitem.label || 'badge badge-success'"
                >{{ subitem.alert }}</span
              >
              <em
                class="{{
                  subitem?.premiumMsg
                    ? subitem.icon + ' no-cursor'
                    : subitem.icon
                }}"
                *ngIf="subitem.icon"
                style="color: #3a393961"
              ></em>
              <span
                style="
                  color: #3a393961;
                  cursor: not-allowed !important;
                  pointer-events: none;
                "
              >
                {{ (subitem.translate | translate) || subitem.text }}
                <span style="cursor: not-allowed !important">
                  <span
                    style="
                      background-color: transparent;
                      font-weight: 500;
                      border-radius: 5px;
                      font-size: 70%;
                    "
                  >
                    <!-- background-color: #07509D; -->
                    <!-- <span style="padding: 2%;color: white;font-weight: bold;font-size: smaller;">
                      Premium
                    </span> -->
                    &nbsp;
                    <i
                      class="fas fa-crown"
                      style="color: #07509d; font-size: 14px"
                    ></i>

                    <!-- <span style="padding: 2%;" data-toggle="tooltip" trigger="hover" triggers="mouseenter:mouseleave" placement="right"
                  ngbPopover="Vivamus sagittis lacus vel augue laoreet rutrum faucibus." popoverTitle={{subitem.text}}>
                    UPGRADE
                  </span>  -->
                  </span>
                </span>
              </span>
            </a>
            <!-- sublevel: single menu item without premium flag -->
            <a
              href
              mat-ripple
              *ngIf="
                !subitem.submenu &&
                !subitem.elink &&
                !subitem.is_premium &&
                subitem.text !== 'Features Documentation'
              "
              [routerLink]="subitem.link"
              [attr.route]="subitem.link"
              title="{{ subitem.text }}"
            >
              <span
                class="float-right"
                *ngIf="subitem.alert"
                [ngClass]="subitem.label || 'badge badge-success'"
                >{{ subitem.alert }}</span
              >
              <em class="{{ subitem.icon }}" *ngIf="subitem.icon"></em>
              <span>{{ (subitem.translate | translate) || subitem.text }}</span>
            </a>
            <a
              href="javascript:void(0)"
              mat-ripple
              *ngIf="
                !subitem.submenu &&
                !subitem.elink &&
                !subitem.is_premium &&
                subitem.text === 'Features Documentation'
              "
              (click)="openHelpDocs()"
              title="{{ subitem.text }}"
            >
              <span
                class="float-right"
                *ngIf="subitem.alert"
                [ngClass]="subitem.label || 'badge badge-success'"
                >{{ subitem.alert }}</span
              >
              <em class="{{ subitem.icon }}" *ngIf="subitem.icon"></em>
              <span>{{ (subitem.translate | translate) || subitem.text }}</span>
            </a>

            <!-- sublevel: has submenu -->
            <a
              href
              mat-ripple
              *ngIf="subitem.submenu"
              title="{{ subitem.text }}"
              (click)="toggleSubmenuClick($event)"
              (mouseenter)="toggleSubmenuHover($event)"
            >
              <span
                class="float-right"
                *ngIf="subitem.alert"
                [ngClass]="subitem.label || 'badge badge-success'"
                >{{ subitem.alert }}</span
              >
              <em class="{{ subitem.icon }}" *ngIf="subitem.icon"></em>
              <span>{{ (subitem.translate | translate) || subitem.text }}</span>
            </a>
            <!-- SUBLEVEL 2 -->
            <ul
              *ngIf="subitem.submenu"
              class="sidebar-nav sidebar-subnav level2"
              [routerLinkActive]="['opening']"
            >
              <li
                *ngFor="let subitem2 of subitem.submenu"
                [routerLinkActive]="['active']"
              >
                <!-- sublevel 2: single menu item  -->
                <a
                  href
                  mat-ripple
                  *ngIf="!subitem2.submenu"
                  [routerLink]="subitem2.link"
                  [attr.route]="subitem2.link"
                  title="{{ subitem2.text }}"
                >
                  <span
                    class="float-right"
                    *ngIf="subitem2.alert"
                    [ngClass]="subitem2.label || 'badge badge-success'"
                    >{{ subitem2.alert }}</span
                  >
                  <em class="{{ subitem2.icon }}" *ngIf="subitem2.icon"></em>
                  <span>{{
                    (subitem2.translate | translate) || subitem2.text
                  }}</span>
                </a>
                <!-- sublevel2: has submenu -->
                <a
                  href
                  mat-ripple
                  *ngIf="subitem2.submenu"
                  title="{{ subitem2.text }}"
                  (click)="toggleSubmenuClick($event)"
                  (mouseenter)="toggleSubmenuHover($event)"
                >
                  <span
                    class="float-right"
                    *ngIf="subitem2.alert"
                    [ngClass]="subitem2.label || 'badge badge-success'"
                    >{{ subitem2.alert }}</span
                  >
                  <em class="{{ subitem2.icon }}" *ngIf="subitem2.icon"></em>
                  <span>{{
                    (subitem2.translate | translate) || subitem2.text
                  }}</span>
                </a>
                <!-- SUBLEVEL 3 -->
                <ul
                  *ngIf="subitem2.submenu"
                  class="sidebar-nav sidebar-subnav level3"
                  [routerLinkActive]="['opening']"
                >
                  <li
                    *ngFor="let subitem3 of subitem2.submenu"
                    [routerLinkActive]="['active']"
                  >
                    <!-- sublevel 2: single menu item  -->
                    <a
                      href
                      mat-ripple
                      *ngIf="!subitem3.submenu"
                      [routerLink]="subitem3.link"
                      [attr.route]="subitem3.link"
                      title="{{ subitem3.text }}"
                    >
                      <span
                        class="float-right"
                        *ngIf="subitem3.alert"
                        [ngClass]="subitem3.label || 'badge badge-success'"
                        >{{ subitem3.alert }}</span
                      >
                      <em
                        class="{{ subitem3.icon }}"
                        *ngIf="subitem3.icon"
                      ></em>
                      <span>{{
                        (subitem3.translate | translate) || subitem3.text
                      }}</span>
                    </a>
                    <!-- sublevel3: has submenu -->
                    <a
                      href
                      mat-ripple
                      *ngIf="subitem3.submenu"
                      title="{{ subitem3.text }}"
                      (click)="toggleSubmenuClick($event)"
                      (mouseenter)="toggleSubmenuHover($event)"
                    >
                      <span
                        class="float-right"
                        *ngIf="subitem3.alert"
                        [ngClass]="subitem3.label || 'badge badge-success'"
                        >{{ subitem3.alert }}</span
                      >
                      <em
                        class="{{ subitem3.icon }}"
                        *ngIf="subitem3.icon"
                      ></em>
                      <span>{{
                        (subitem3.translate | translate) || subitem3.text
                      }}</span>
                    </a>
                    <!-- SUBLEVEL 4 -->
                    <ul
                      *ngIf="subitem3.submenu"
                      class="sidebar-nav sidebar-subnav level3"
                      [routerLinkActive]="['opening']"
                    >
                      <li
                        *ngFor="let subitem4 of subitem3.submenu"
                        [routerLinkActive]="['active']"
                      >
                        <!-- sublevel 2: single menu item  -->
                        <a
                          href
                          mat-ripple
                          *ngIf="!subitem4.submenu"
                          [routerLink]="subitem4.link"
                          [attr.route]="subitem4.link"
                          title="{{ subitem4.text }}"
                        >
                          <span
                            class="float-right"
                            *ngIf="subitem4.alert"
                            [ngClass]="subitem4.label || 'badge badge-success'"
                            >{{ subitem4.alert }}</span
                          >
                          <em
                            class="{{ subitem4.icon }}"
                            *ngIf="subitem4.icon"
                          ></em>
                          <span>{{
                            (subitem4.translate | translate) || subitem4.text
                          }}</span>
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>

              <li></li>
            </ul>
          </li>

          <li></li>
        </ul>
      </li>
    </ul>
    <!-- END sidebar nav-->
  </nav>
</div>
<!-- END Sidebar (left)-->
<script>
  $(document).ready(function () {
    $('[data-toggle="popover"]').popover({
      placement: "top",
      trigger: "hover",
    });
  });
</script>

<mde-popover
  #appPopover="mdePopover"
  [mdePopoverOverlapTrigger]="false"
  [mdePopoverOffsetX]="230"
  [mdePopoverCloseOnClick]="false"
  [mdeFocusTrapEnabled]="false"
  [mdePopoverArrowOffsetX]="211"
  [mdePopoverArrowWidth]="0"
  mdePopoverArrowColor="#07509d"
  [hidden]="isSidebarCollapsed()"
>
  <div class="info-card-tooltip" [hidden]="isSidebarCollapsed()">
    <div class="col-md-12">
      <p
        style="
          font-size: 20px;
          font-weight: 600;
          text-align: left;
          color: chartreuse;
        "
      >
        You’ve reached a premium feature
      </p>
      <p
        style="
          text-align: left;
          justify-content: center;
          font-size: 14px;
          font-weight: 500 !important;
        "
      >
        <span style="font-weight: bold">{{ title }} - </span> {{ premiumMsg }}
      </p>
      <div style="text-align: center">
        <Button
          class="btn btn-primary btn-md material-cust-small-button"
          (click)="navigateR()"
          style="background-color: #fff; color: black; border-radius: 7px"
          >{{ buttontext }}</Button
        >
      </div>
    </div>
  </div>
</mde-popover>
