import { hierarchy } from '../../environments/environment';
import { link } from 'fs';

const L0Name = hierarchy.level0.toLowerCase().split(' ').join('');
const L1Name = hierarchy.level1.toLowerCase().split(' ').join('');
const L2Name = hierarchy.level2.toLowerCase().split(' ').join('');
const L3Name = hierarchy.level3.toLowerCase().split(' ').join('');

const Home = {
  text: 'Home',
  link: '/home',
  icon: 'bi bi-house-door',
  allowedRoles: ['sudo', 'admin', 'user', 'viewer'],
};
const analytics = {
  text: 'Analytics',
  link: '/advanceAnalytics/dashboard/v1',
  icon: 'bi bi-graph-up',
  allowedRoles: ['sudo', 'admin', 'user', 'viewer'],
};

const CloudEndpoints = {
  text: 'Cloud Endpoints',
  link: '/cloud-endpoints',
  icon: 'bi bi-cloud-arrow-up',
  allowedRoles: ['sudo', 'admin', 'user', 'viewer'],
  premiumMsg:
    'Fast, scalable and secure API gateway that allows to connect IoTSense with a variety of endpoints.',
  buttontext: 'Contact us',
};

const datamanagement = {
  text: 'Data Management',
  link: '/datamanagement',
  icon: 'bi bi-file-earmark-bar-graph',
  submenu: [
    {
      text: 'Data Streams',
      link: '/datamanagement/datastreams',
      allowedRoles: ['sudo', 'admin', 'user', 'viewer'],
    },
    {
      text: 'Export Data',
      link: '/datamanagement/exportdata',
      allowedRoles: ['sudo', 'admin', 'user', 'viewer'],
    },
  ],
};

const resourcemanagement = {
  text: 'Resource Management',
  link: '/resourcemanagement',
  icon: 'bi bi-music-player',
  submenu: [
    {
      text: hierarchy.level2,
      link: '/resourcemanagement/' + L2Name,
      allowedRoles: ['sudo', 'admin', 'user', 'viewer'],
      premiumMsg:
        'Machine groups allows you to group machines and operate them according to your needs.',
    },
    {
      text: hierarchy.level1,
      link: '/resourcemanagement/' + L1Name,
      allowedRoles: ['sudo', 'admin', 'user', 'viewer'],
    },
    {
      text: hierarchy.level0,
      link: '/resourcemanagement/' + L0Name,
      allowedRoles: ['sudo', 'admin', 'user', 'viewer'],
    },
    {
      text: 'Commands',
      link: '/resourcemanagement/commands',
      allowedRoles: ['sudo', 'admin', 'user', 'viewer'],

      premiumMsg:
        'Control your resources from IoTSense by configuring custom commands.',
      buttontext: 'Contact us',
    },
  ],
};

const ProtocolAdapters = {
  text: 'Protocol Adapters',
  link: '/protocol',
  icon: 'bi bi-diagram-3',
  allowedRoles: ['sudo', 'admin', 'user', 'viewer'],
  premiumMsg:
    'Collects data from local devices using the Ethernet/IP protocol.',
  buttontext: 'Contact us',
};

const dataflow = {
  text: 'Dataflow Management',
  link: '/dataflow',
  icon: 'bi bi-shuffle',
  submenu: [
    {
      text: 'Things Dataflow',
      link: '/dataflow/things',
      allowedRoles: ['sudo', 'admin', 'user', 'viewer'],
    },
    {
      text: 'Advanced Dataflow',
      link: '/dataflow/advanced',
      allowedRoles: ['sudo', 'admin', 'user', 'viewer'],
    },
  ],
  premiumMsg:
    'This feature enables you to create custom business logic with ease. It also helps you modify data and automate flows.',
  buttontext: 'Contact us',
};
const DataTable = {
  text: 'Data Tables',
  link: '/data-table',
  icon: 'bi bi-table',
  allowedRoles: ['sudo', 'admin', 'user', 'viewer'],
};

// const CustomMENUS = {
//   text: 'Custom Menu',
//   link: '/data-table',
//   icon: 'bi bi-hash',
//   allowedRoles: ['sudo'],
//   submenu: [
//     {
//       text: 'Test123',
//       link: '/data-table/view/6233643de92d17fe0ed7270b',
//       allowedRoles: ['sudo'],
//     },
//   ]
// };

const taskmanagement = {
  text: 'Task Management',
  link: '/taskmanagement',
  icon: 'bi bi-ui-checks',
  submenu: [
    {
      text: 'Task Settings',
      link: '/taskmanagement/task-settings',
      allowedRoles: ['sudo', 'admin', 'user', 'viewer'],
    },
    {
      text: 'Task History',
      link: '/taskmanagement/task-history',
      allowedRoles: ['sudo', 'admin', 'user', 'viewer'],
    },
    {
      text: 'Task Creation',
      link: '/taskmanagement/task-creation',
      allowedRoles: ['sudo', 'admin', 'user', 'viewer', 'operator'],
    },
    {
      text: 'Task Transaction',
      link: '/taskmanagement/task-transaction',
      allowedRoles: ['sudo', 'admin', 'user', 'viewer'],
    },
  ],
  premiumMsg:
    'The proposed feature is expected to provide users with increased convenience and more efficient task management.',
  buttontext: 'Contact us',
};
const routecard = {
  text: 'Route Card',
  link: '/routecard',
  icon: 'bi bi-clipboard-check',
  submenu: [
    {
      text: 'Route Card Settings',
      link: '/routecard/routecard-settings',
      allowedRoles: ['sudo', 'admin', 'user', 'viewer'],
    },
    {
      text: 'Route Card List',
      link: '/routecard/routecard-list',
      allowedRoles: ['sudo', 'admin', 'user', 'viewer'],
    },
  ],
  premiumMsg:
    'It lists the manufacturing operations in the decided sequence along with the machines associated with each operation.',
  buttontext: 'Contact us',
};
// const MachineVision = {
//   text: 'Machine Vision',
//   link: '/edge-analytics/images',
//   icon: 'bi bi-eye',
//   allowedRoles: ['sudo', 'admin', 'user', 'viewer'],
//   premiumMsg:'Machine vision inspection can reduce product defects, increase yields, track parts and products through production.',
//   buttontext:'Contact us'

// };

const HelpCenter = {
  text: 'Help Center',
  // link: '/help-center',
  icon: 'bi bi-question-square',
  allowedRoles: ['sudo', 'admin', 'user', 'viewer'],
  submenu: [
    {
      text: 'Features Documentation',
      // link: '/help',
      allowedRoles: ['sudo', 'admin', 'user', 'viewer'],
    },
    {
      text: 'API Documentation',
      link: 'help-center/apis',
      allowedRoles: ['sudo', 'admin', 'user', 'viewer'],
    },
  ],
};
const Dashboard = {
  text: 'Dashboard',
  link: '/dashboard',
  icon: 'icon-speedometer',
  submenu: [
    {
      text: 'Dashbord v1',
      link: '/home/dashboard/v1',
    },
    {
      text: 'Dashbord v2',
      link: '/home/dashboard/v2',
    },
    {
      text: 'Dashbord v3',
      link: '/home/dashboard/v3',
    },
  ],
};

const Widgets = {
  text: 'Widgets',
  link: '/home/widgets',
  icon: 'icon-grid',
};

const Material = {
  text: 'Material',
  link: '/home/material',
  icon: 'fa fa-shield-alt',
  submenu: [
    {
      text: 'Widgets',
      link: '/home/material/widgets',
    },
    {
      text: 'Cards',
      link: '/home/material/cards',
    },
    {
      text: 'Forms',
      link: '/home/material/forms',
    },
    {
      text: 'Inputs',
      link: '/home/material/inputs',
    },
    {
      text: 'Lists',
      link: '/home/material/lists',
    },
    {
      text: 'Whiteframe',
      link: '/home/material/whiteframe',
    },
    {
      text: 'Colors',
      link: '/home/material/colors',
    },
    {
      text: 'ng2Material',
      link: '/home/material/ngmaterial',
    },
  ],
  alert: 'new',
  label: 'badge badge-primary',
};

const Elements = {
  text: 'Elements',
  link: '/elements',
  icon: 'icon-chemistry',
  submenu: [
    {
      text: 'Buttons',
      link: '/elements/buttons',
    },
    {
      text: 'Interaction',
      link: '/elements/interaction',
    },
    {
      text: 'Notification',
      link: '/elements/notification',
    },
    {
      text: 'SweetAlert',
      link: '/elements/sweetalert',
    },
    {
      text: 'Spinners',
      link: '/elements/spinners',
    },
    {
      text: 'Dropdown',
      link: '/elements/dropdown',
    },
    {
      text: 'Nav Tree',
      link: '/elements/navtree',
    },
    {
      text: 'Sortable',
      link: '/elements/sortable',
    },
    {
      text: 'Grid',
      link: '/elements/grid',
    },
    {
      text: 'Grid Masonry',
      link: '/elements/gridmasonry',
    },
    {
      text: 'Typography',
      link: '/elements/typography',
    },
    {
      text: 'Font Icons',
      link: '/elements/iconsfont',
    },
    {
      text: 'Weahter Icons',
      link: '/elements/iconsweather',
    },
    {
      text: 'Colors',
      link: '/elements/colors',
    },
    {
      text: 'Infinite Scroll',
      link: '/elements/infinitescroll',
    },
  ],
};

const Forms = {
  text: 'Forms',
  link: '/forms',
  icon: 'icon-note',
  submenu: [
    {
      text: 'Standard',
      link: '/forms/standard',
    },
    {
      text: 'Extended',
      link: '/forms/extended',
    },
    {
      text: 'Validation',
      link: '/forms/validation',
    },
    {
      text: 'Upload',
      link: '/forms/upload',
    },
    {
      text: 'Image Crop',
      link: '/forms/cropper',
    },
  ],
};

const Charts = {
  text: 'Charts',
  link: '/charts',
  icon: 'icon-graph',
  submenu: [
    {
      text: 'Flot',
      link: '/charts/flot',
    },
    {
      text: 'Radial',
      link: '/charts/radial',
    },
    {
      text: 'ChartJS',
      link: '/charts/chartjs',
    },
  ],
};

const Tables = {
  text: 'Tables',
  link: '/tables',
  icon: 'icon-grid',
  submenu: [
    {
      text: 'Standard',
      link: '/tables/standard',
    },
    {
      text: 'Extended',
      link: '/tables/extended',
    },
    {
      text: 'Angular Grid',
      link: '/tables/aggrid',
    },
    {
      text: 'NGxDatatables',
      link: '/tables/ngxdatatable',
    },
  ],
};

const Maps = {
  text: 'Maps',
  link: '/maps',
  icon: 'icon-map',
  submenu: [
    {
      text: 'Google',
      link: '/maps/google',
    },
    {
      text: 'Vector',
      link: '/maps/vector',
    },
  ],
};

const Pages = {
  text: 'Pages',
  link: '/pages',
  icon: 'icon-doc',
  submenu: [
    {
      text: 'Login',
      link: '/login',
    },
    {
      text: 'Register',
      link: '/register',
    },
    {
      text: 'Recover',
      link: '/recover',
    },
    {
      text: 'Lock',
      link: '/lock',
    },
    {
      text: '404',
      link: '/404',
    },
    {
      text: '500',
      link: '/500',
    },
    {
      text: 'Maintenance',
      link: '/maintenance',
    },
  ],
};

const Blog = {
  text: 'Blog',
  link: '/blog',
  icon: 'icon-notebook',
  submenu: [
    {
      text: 'List',
      link: '/blog/list',
    },
    {
      text: 'Post',
      link: '/blog/post',
    },
    {
      text: 'Articles',
      link: '/blog/articles',
    },
    {
      text: 'Article View',
      link: '/blog/articleview',
    },
  ],
};

const Ecommerce = {
  text: 'Ecommerce',
  link: '/ecommerce',
  icon: 'icon-basket-loaded',
  submenu: [
    {
      text: 'Orders',
      link: '/ecommerce/orders',
    },
    {
      text: 'Order View',
      link: '/ecommerce/orderview',
    },
    {
      text: 'Products',
      link: '/ecommerce/products',
    },
    {
      text: 'Product View',
      link: '/ecommerce/productview',
    },
    {
      text: 'Checkout',
      link: '/ecommerce/checkout',
    },
  ],
};

const Extras = {
  text: 'Extras',
  link: '/extras',
  icon: 'icon-cup',
  submenu: [
    {
      text: 'Contacts',
      link: '/extras/contacts',
    },
    {
      text: 'Contact details',
      link: '/extras/contactdetails',
    },
    {
      text: 'Projects',
      link: '/extras/projects',
    },
    {
      text: 'Projects details',
      link: '/extras/projectsdetails',
    },
    {
      text: 'Team Viewer',
      link: '/extras/teamviewer',
    },
    {
      text: 'Social Board',
      link: '/extras/socialboard',
    },
    {
      text: 'Vote links',
      link: '/extras/votelinks',
    },
    {
      text: 'Bug tracker',
      link: '/extras/bugtracker',
    },
    {
      text: 'Faq',
      link: '/extras/faq',
    },
    {
      text: 'Help center',
      link: '/extras/helpcenter',
    },
    {
      text: 'Followers',
      link: '/extras/followers',
    },
    {
      text: 'Settings',
      link: '/extras/settings',
    },
    {
      text: 'Plans',
      link: '/extras/plans',
    },
    {
      text: 'File manager',
      link: '/extras/filemanager',
    },
    {
      text: 'Forum',
      link: '/extras/forum',
    },
    {
      text: 'Mailbox',
      link: '/extras/mailbox',
    },
    {
      text: 'Timeline',
      link: '/extras/timeline',
    },
    {
      text: 'Calendar',
      link: '/extras/calendar',
    },
    {
      text: 'Invoice',
      link: '/extras/invoice',
    },
    {
      text: 'Search',
      link: '/extras/search',
    },
    {
      text: 'Todo list',
      link: '/extras/todolist',
    },
    {
      text: 'Profile',
      link: '/extras/profile',
    },
    {
      text: 'Code editor',
      link: '/extras/codeeditor',
    },
  ],
};

const settings = {
  text: 'Settings',
  link: '/settings',
  icon: 'bi bi-gear',
  submenu: [
    {
      text: 'General',
      link: '/settings/general',
      allowedRoles: ['sudo', 'admin', 'user'],
    },
    {
      text: 'Connectivity',
      link: '/settings/connectivity',
      allowedRoles: ['sudo', 'admin'],
    },
    {
      text: 'System',
      link: '/settings/system',
      allowedRoles: ['sudo', 'admin', 'user'],
    },
    {
      text: 'Remote Config',
      link: '/settings/remote-config',
      allowedRoles: ['sudo', 'admin'],
    },
    {
      text: 'Alert Config',
      link: '/settings/alert-config',
      allowedRoles: ['sudo', 'admin'],
    },
    {
      text: 'Email',
      link: '/settings/email',
      allowedRoles: ['sudo', 'admin'],
    },
    {
      text: 'SMS',
      link: '/settings/sms',
      allowedRoles: ['sudo', 'admin'],
    },
    {
      text: 'Backup & Restore',
      link: '/settings/backup-restore',
      allowedRoles: ['sudo', 'admin'],
    },
    {
      text: 'Log',
      link: '/settings/log',
      allowedRoles: ['sudo', 'admin'],
    },
  ],
};

const usermanagement = {
  text: 'User Management',
  link: '/usermanagement',
  icon: 'bi bi-people',
  allowedRoles: ['sudo', 'admin', 'user', 'viewer'],
  premiumMsg:
    'User management is a mechanism which involves defining and managing users, roles and their access levels in a system.',
  buttontext: 'Contact us',
};

const smartActions = {
  text: 'Smart Actions',
  link: '/smart-action',
  icon: 'bi bi-sliders',
  submenu: [
    {
      text: 'Triggers & Alerts',
      link: '/smart-action/triggers',
      allowedRoles: ['sudo', 'admin', 'user', 'viewer'],
    },
    {
      text: 'Smart Security',
      link: '/smart-action/smartsecurity/list-devices',
      allowedRoles: ['sudo', 'admin', 'user', 'viewer'],
      premiumMsg:
        'Smart security using Expert and ML modes allows to monitor data based on complex conditions and to take action.',
      buttontext: 'Contact us',
    },
  ],
};

const headingMain = {
  text: 'Main Navigation',
  heading: true,
};

const headingComponents = {
  text: 'Components',
  heading: true,
};

const headingMore = {
  text: 'More',
  heading: true,
};

export const menu = [
  headingMain,
  Home,
  analytics,
  CloudEndpoints,
  datamanagement,
  resourcemanagement,
  ProtocolAdapters,
  dataflow,
  DataTable,
  taskmanagement,
  routecard,
  // MachineVision,
  usermanagement,
  smartActions,
  settings,
  HelpCenter,
  // headingComponents,
];
