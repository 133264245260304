<div class="row">
  <div class="col-md-11" style="text-align: center">
    <h3 class="headText1" style="margin-bottom: 0px !important">
      Clone Record
    </h3>
  </div>
  <div class="col-md-1">
    <button type="button" class="close" mat-dialog-close>
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
</div>
<hr />

<div mat-dialog-body>
  <div class="section" style="margin-top: 0.5em">
    <mat-form-field appearance="outline" style="display: block">
      <mat-label>Name</mat-label>
      <input
        matInput
        #name="ngModel"
        oninput="this.value = this.value.replace(/[^A-Za-z0-9\S ]|^ /g,'')"
        maxlength="40"
        [(ngModel)]="deviceName"
        required
      />
      <mat-error *ngIf="!name.valid">Name is required.</mat-error>
    </mat-form-field>
  </div>
</div>
<div
  class="modal-footer"
  style="
    text-align: center;
    display: block;
    border-top: none;
    padding-bottom: 0px !important;
    padding-top: 7%;
  "
>
  <button
    [disabled]="!name.valid"
    mat-raised-button
    class="btn btn-primary btn-lg"
    (click)="create(data, deviceName)"
    mat-dialog-close
  >
    Create
  </button>
</div>
