import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class subscription {
  serverBaseUrl: string = environment.apiUrl;

  constructor(private _http: HttpClient) {}

  /**
   * Creates a new subscription
   */
  createSubscription(subsDetails: any): Observable<any> {
    return this._http.post(
      `${this.serverBaseUrl}/api/payment/createOrder`,
      subsDetails,
      { withCredentials: true }
    );
  }

  /**
   * Cancels the subscription
   */
  cancelSubscription(reqObj: any): Observable<any> {
    return this._http.post(
      `${this.serverBaseUrl}/api/subscription/cancel`,
      reqObj,
      { withCredentials: true }
    );
  }

  /**
   * Fetches subscription details for specific user
   */
  getSubscriptionDetails(userId: string, subscripId: string): Observable<any> {
    return this._http.get(
      `${this.serverBaseUrl}/api/user/${userId}/subscription/${subscripId}`,
      { withCredentials: true }
    );
  }

  /**
   * Upgrades the plans
   */
  upgradePlan(
    userId: string,
    subscriptionId: string,
    reqObj: any
  ): Observable<any> {
    return this._http.post(
      `${this.serverBaseUrl}/api/user/${userId}/subscription/${subscriptionId}`,
      reqObj,
      { withCredentials: true }
    );
  }

  /**
   * Authenticate the details of successful payment
   */
  verifyRzpPayment(reqObj: any): Observable<any> {
    return this._http.post(`${this.serverBaseUrl}/api/payment/verify`, reqObj, {
      withCredentials: true,
    });
  }

  /**
   * Fetches all the plans from razorpay
   */
  getRazorpayPlans(): Observable<any> {
    return this._http.get(`${this.serverBaseUrl}/api/plans`, {
      withCredentials: true,
    });
  }
  /**
   * Fetches user associated subscription Info from local DB
   */
  getSubDetails(userId: any): Observable<any> {
    return this._http.get(
      `${this.serverBaseUrl}/api/user/subscriptioninfo/` + userId,
      {
        withCredentials: true,
      }
    );
  }
}
