import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  TemplateRef,
  NgZone,
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LoginService } from '../login/login.service';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { ColorsService } from './../../../shared/colors/colors.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { TitleCasePipe } from '@angular/common';
import { LocalStorageService } from 'src/app/shared/local-storage.service';
import { SocketIoService } from 'src/app/core/socket-io.service';
import { subscription } from './subscription.service';
import { takeUntil } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { environment, ENV } from '../../../../environments/environment';
import { SpinnerService } from 'src/app/shared/spinner.service';
const swal = require('sweetalert');
import { PLANS } from 'src/assets/plans';

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.scss'],
  providers: [LoginService],
})
export class SubscriptionComponent implements OnInit, OnDestroy {
  _utils: any;
  showOnlyFreeTrial = environment.showOnlyFreeTrial;

  @ViewChild('rzpPaymentProcessTemp') rzpPaymentProcessTemp: TemplateRef<any>;
  unsubscribe$ = new Subject<boolean>();
  /** Styles array for plans cards */
  stylesArray = [
    {
      txtStyle: `color: #4ca6af;`,
      type: 'free trial',
    },
    {
      type: 'basic monthly-1',
      txtStyle: `color: ${this._color.byName('success')};`,
      btnStyle: `color: ${this._color.byName(
        'success'
      )}; line-height: 50px;border-color: ${this._color.byName('success')};`,
    },
    {
      type: 'basic yearly -1',
      txtStyle: `color: ${this._color.byName('success')};`,
      btnStyle: `color: ${this._color.byName(
        'success'
      )}; line-height: 50px;border-color: ${this._color.byName('success')};`,
    },
    {
      type: 'pro yearly -1',
      txtStyle: `color: ${this._color.byName('primary')};`,
      btnStyle: `color: ${this._color.byName(
        'primary'
      )}; line-height: 50px;border-color: ${this._color.byName('primary')};`,
    },
    {
      type: 'pro monthly -1',
      txtStyle: `color: ${this._color.byName('primary')};`,
      btnStyle: `color: ${this._color.byName(
        'primary'
      )}; line-height: 50px;border-color: ${this._color.byName('primary')};`,
    },
    {
      type: 'enterprise',
      txtStyle: `color: #c84545;`,
    },
  ];
  enterprisePlan: any = {
    type: 'enterprise',
    benefits: [
      'On-premise',
      'Unlimited users',
      'Unlimited things',
      'Edge communication',
      'Send commands to things',
      'Unlimited cloud endpoints',
      '3rd party software integration',
      'Dataflow management',
      'Unlimited datatables',
      'Production task management',
    ],
    status: '',
  };
  freePlan: any = {
    type: 'free trial',
    benefits: [
      '14-day subscription',
      'For individual user',
      'Cloud based',
      'Upto 5 things',
      'Upto 500 datastreams/day',
      'Upto 5 flexible datatables',
      'Alerts & Triggers',
      'Upto 4 dashboards',
      'Customized downloadable reports',
      'Digital Twin',
      'Send commands to things',
    ],
    status: '',
  };
  user: any;
  isNewUser: boolean = true;
  isPlansPage: boolean = true;
  selectedPlan: any;
  isMonthly: boolean = true;
  rzpMonthlyPlans: Array<any> = [];
  rzpYearlyPlans: Array<any> = [];
  monthlyPlans: Array<any> = [];
  yearlyPlans: Array<any> = [];
  checkoutOptions: any;
  rzpSubData: any;
  paidSubReq: any;
  userSubInfo: any;
  plansList: Array<any>;
  showSpinner: boolean = false;
  paymentCaptured: boolean = false;
  dialogRef: MatDialogRef<any>;
  basicMonthlyFlag: boolean = false;
  basicYearlyFlag: boolean = false;
  proMonthlyFlag: boolean = false;
  proYearlyFlag: boolean = false;
  freeFlag: boolean = false;
  metaData: any = {};
  currentPlan: any;

  constructor(
    private _loginService: LoginService,
    private _snackBar: MatSnackBar,
    private _spinner: SpinnerService,
    private _toaster: ToastrService,
    private _router: Router,
    private _color: ColorsService,
    private _localStorage: LocalStorageService,
    private _snackbar: MatSnackBar,
    private _dialog: MatDialog,
    private _socket: SocketIoService,
    private _subscription: subscription,
    private ngZone: NgZone
  ) {}

  ngOnInit(): void {
    if (window['user']) this.user = window['user'];
    else this._router.navigate(['/login']);
    this._socket.setupSocketConnection();
    this._socket.emit('joinNoProject', this.user._id);
    this.initRzpSocket();
    if (
      this.user.subId?.planName == 'basic yearly -1' ||
      (this.user.subId?.planName == 'pro yearly -1' &&
        this.user.subId?.status == 'active')
    ) {
      this.isMonthly = false;
    }
    if (
      this.user.subId?.planName == 'basic monthly-1' &&
      this.user.subId?.status == 'active'
    ) {
      this.basicMonthlyFlag = true;
    } else if (
      this.user.subId?.planName == 'basic yearly -1' &&
      this.user.subId?.status == 'active'
    ) {
      this.basicYearlyFlag = true;
    } else if (
      this.user.subId?.planName == 'pro monthly -1' &&
      this.user.subId?.status == 'active'
    ) {
      this.proMonthlyFlag = true;
    } else if (
      this.user.subId?.planName == 'pro yearly -1' &&
      this.user.subId?.status == 'active'
    ) {
      this.proYearlyFlag = true;
    } else {
      this.freeFlag = true;
    }

    this.userSubInfo = this.user.subId;
    this._spinner.showSpinner();
    this.getPlans();
    // Options required for payment checkout via razorpay
    this.checkoutOptions = {
      key: environment.rzpKey,
      name: environment.appName,
      description: 'Subscription',
      image: `${environment.apiUrl}/assets/img/brand/rzp-logo.png`,
      handler: (response: any) => {
        this.verifyRzpPayment(response);
      },
      prefill: {
        name: this.user.displayName,
        email: this.user.email,
      },
      theme: {
        color: '#07509d',
      },
    };
  }

  /**
   * SOCKET - Listen for subscription payment done successfull
   */
  initRzpSocket(): void {
    this._socket.listen('RzpPaymentDone').subscribe(
      (data: any) => {
        if (data && data.rzpPayStatus) {
          this._spinner.stopSpinner();
          this.showSpinner = false;
          this.dialogRef.close();
          this._localStorage.setItem('paymentCaptured', data.rzpPayStatus);
          this._router.navigate(['/dashboard']);
          if (data.subId && !data.isUpgraded) {
            this._toaster.success(
              `Welcome aboard
              this.user.firstName!`,
              'Subscription'
            );
          }
        } else {
          this._spinner.stopSpinner();
          this.paymentCaptured = false;
          this.showSpinner = false;
          this.dialogRef.close();
          this._localStorage.setItem('paymentCaptured', false);
          if (data.isUpgraded) {
            this._router.navigate(['/dashboard']);
            this._toaster.success(
              'Your plan have been successfully upgraded. Please check you email for details',
              'Subscription'
            );
          }
        }
      },
      (err: Error) => {
        this._spinner.stopSpinner();
        this._toaster.error(`Socket Error - ${err.message}`, 'Train Model');
      }
    );
  }

  /**
   * Fetches all the plans from razorpay
   */
  getPlans(): void {
    this._subscription
      .getRazorpayPlans()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res: any) => {
          if (res.length) {
            this.plansList = res;
            res.forEach((plan: any) => {
              if (plan.period === 'monthly') {
                this.rzpMonthlyPlans.push(plan);
              } else {
                this.rzpYearlyPlans.push(plan);
              }
            });
            this.generatePlansArray(this.isMonthly);
          } else {
            this._spinner.stopSpinner();
            this._toaster.error('Sorry! No plans found', 'Subscription');
          }
        },
        (err: HttpErrorResponse) => {
          this._spinner.stopSpinner();
          this._toaster.error(
            err.error.message || 'Sorry! Cannot fetch plans',
            'Subscription'
          );
        }
      );
  }
  /**
   * Authenticate the details of successful payment
   * @param checkoutData
   */
  verifyRzpPayment(checkoutData: any): void {
    if (checkoutData.razorpay_payment_id) {
      const { rzpPlanId, rzpSubId, subscription: subId } = this.rzpSubData;
      let reqObj = {
        ...checkoutData,
        rzpPlanId,
        rzpSubId,
        subId,
        userId: this.user._id,
      };
      this._subscription
        .verifyRzpPayment(reqObj)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          (res: any) => {
            if (res.isVerified) {
              this.user.status = 'active';
              this.user.subscription = res.subId;
              this.user.rzpPlanId = rzpPlanId;
              this.user.hasFreeTrialUsed = true;

              if (res.subId && !res.isUpgraded) {
                this._spinner.stopSpinner();
                this.dialogRef = this._dialog.open(this.rzpPaymentProcessTemp, {
                  minHeight: '40vh',
                  minWidth: '30vw',
                });
                this.showSpinner = true;
                this._subscription
                  .getSubscriptionDetails(this.user._id, res.subId)
                  .pipe(takeUntil(this.unsubscribe$))
                  .subscribe(
                    (data: any) => {
                      if (data) {
                        let userSubsType =
                          !data.planType && data.isFreeTrial
                            ? 'pro'
                            : data.planType;
                        environment.appType === 'saas' &&
                          this.readPlanDetailsFromServer(
                            userSubsType,
                            this.user
                          );
                      }
                    },
                    (err: HttpErrorResponse) => {
                      this._spinner.stopSpinner();
                    }
                  );
              } else if (res.isUpgraded) {
                this._subscription
                  .getSubscriptionDetails(this.user._id, res.subId)
                  .pipe(takeUntil(this.unsubscribe$))
                  .subscribe(
                    (data: any) => {
                      if (data) {
                        // reading the plan details form the server based on the user plan type.
                        let userSubsType =
                          !data.planType && data.isFreeTrial
                            ? 'pro'
                            : data.planType;
                        environment.appType === 'saas' &&
                          this.readPlanDetailsFromServer(
                            userSubsType,
                            this.user,
                            res?.isUpgraded
                          );
                      }
                    },
                    (err: HttpErrorResponse) => {
                      this._spinner.stopSpinner();
                    }
                  );
              }
            } else {
              this._spinner.stopSpinner();
              this.showSpinner = false;
              this.dialogRef.close();
              this._toaster.error(
                'Failed! Your payment source is not authenticated or is pending. Please contact support or admin',
                'Subscription'
              );
            }
          },
          (err: HttpErrorResponse) => {
            this._spinner.stopSpinner();
            this.showSpinner = false;
            this.dialogRef.close();
            this._toaster.error(
              err.error.message ||
                'Sorry! Your payment source is not authenticated',
              'Subscription'
            );
          }
        );
    }
  }

  /**
   * funtion to get the restriction details from server based on the plan type.
   * @param userPlanType
   */
  readPlanDetailsFromServer(
    userPlanType: string,
    res,
    isUpgradeReq?: boolean
  ): void {
    res.psFeatures = window['user'].subId;
    res.planType = userPlanType;
    this._localStorage.setItem('user', res);
    if (res.isFreeTrial) {
      this._spinner.stopSpinner();
      this._router.navigate(['/dashboard']);
    }
  }

  handleSubscription(isFree: boolean, planData?: any): void {
    var options = {};
    // Free trial subscription
    if (isFree) {
      this._subscription.createSubscription(options).subscribe((data: any) => {
        this._toaster.success(data.message, 'Subscription');
        this.signout();
        this._router.navigate(['/login']);
      });
    } else if (this.user.subId && this.userSubInfo.rzpPlanId) {
      if (!this.userSubInfo.upcomingPlans)
        this.upgradeUserPlan(planData, isFree);
      else
        this._toaster.error(
          'You already have one plan to update in your cart.',
          'Subscription'
        );
    } else {
      this.currentPlan = planData;
      // Check for similar plans selection
      if (planData.type == 'basic yearly -1') {
        (options['plan_id'] = 'plan_IAlIQRaeRY5mN7'),
          (options['total_count'] = 12);
        // (options['type'] = 'basic yearly -1');
      } else if (planData.type == 'basic monthly-1') {
        (options['plan_id'] = 'plan_IAlHcjUrNdDQP6'),
          (options['total_count'] = 12);
        // (options['type'] = 'basic monthly-1');
      } else if (planData.type == 'pro monthly -1') {
        (options['plan_id'] = 'plan_IAlOKwFJ9DG5aG'),
          (options['total_count'] = 12);
        // (options['type'] = 'pro monthly -1');
      } else {
        (options['plan_id'] = 'plan_IAlNpcijQ9sHc2'),
          (options['total_count'] = 12);
        // (options['type'] = 'pro yearly -1');
      }

      this._subscription.createSubscription(options).subscribe((data: any) => {
        this.metaData = {
          subId: data?.sub?.id,
          planType: this.currentPlan?.type,
          planId: options['plan_id'],
        };
        this.verifyPayment(data?.sub?.id);
      });
    }
  }

  verifyPayment(param) {
    var options = {
      key: document.domain == 'localhost' ? ENV.DEV_KEY : ENV.PROD_KEY, // Enter the Key ID generated from the Dashboard
      name: 'IoTSense',
      description: 'Test Transaction',
      image: 'https://uat.iotsense.io/images/iotsense.ico',
      subscription_id: param, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
      handler: this.paymentCapture.bind(this),
      prefill: {
        name: this.user.displayName,
        email: this.user.email,
        // contact: this.user.phone.phone,
      },
      theme: {
        color: '#07509d',
      },
    };
    var rzp1 = new window.Razorpay(options);

    rzp1.on('payment.failed', function (response) {
      this._toaster.error(response.error.description, 'Subscription');
    });
    rzp1.open();
  }
  paymentCapture(response) {
    var options = {
      razorpay_payment_id: response.razorpay_payment_id,
      razorpay_order_id: response.razorpay_subscription_id,
      razorpay_signature: response.razorpay_signature,
      planType: this.currentPlan.type,
      planDuration: this.isMonthly ? 'Monthly' : 'Yearly',
      rzpPlanId: this.metaData?.planId,
      rzpSubId: this.metaData?.subId,
    };
    this._subscription.verifyRzpPayment(options).subscribe(
      (rzpres: any) => {
        // console.log('\n\n🚀 + rzpres', rzpres);
        this._toaster.success(rzpres.message, 'Subscription');
        this.signout();
        this.ngZone.run(() => this._router.navigate(['/login'])).then();
      },
      (err: any) => {
        this._toaster.error(err.message, 'Subscription');
      }
    );
  }

  /**
   * Updates the subscription details or plan upgrade need to add the restrictions here also.
   */
  upgradeSubscription(planData: any, isFree: boolean): void {
    const { txtStyle, btnStyle, ...reqObj } = planData;
    reqObj.planDuration = this.isMonthly ? 'monthly' : 'yearly';
    reqObj.isUpgradeImmediately = environment.isUpgradeImmediately;
    reqObj.createdBy = this.user._id;
    reqObj.subId = this.user.subscription;
    // API call
    if (!isFree) {
      this._subscription
        .upgradePlan(this.user._id, this.user.subscription, reqObj)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          (res: any) => {
            if (res && res.upcomingPlan) {
              this._subscription
                .getSubscriptionDetails(this.user._id, this.user.subscription)
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe(
                  (data: any) => {
                    if (data) {
                      // reading the plan details form the server based on the user plan type.
                      let userSubsType =
                        !data.planType && data.isFreeTrial
                          ? 'pro'
                          : data.planType;
                      environment.appType === 'saas' &&
                        this.readPlanDetailsFromServer(userSubsType, res);
                      this._spinner.stopSpinner();
                    }
                  },
                  (err: HttpErrorResponse) => {
                    this._spinner.stopSpinner();
                    this._toaster.error(
                      err.error.message ||
                        'Unable to fetch subscription details',
                      'Subscription'
                    );
                  }
                );
              this._toaster.success(
                'Your plan have been successfully upgraded. Please check you email for details',
                'Subscription'
              );
            } else {
              this._toaster.error(
                'Sorry! Cannot upgrade your plan',
                'Subscription'
              );
            }
          },
          (err: HttpErrorResponse) => {
            this._spinner.stopSpinner();
            this._toaster.error(
              err.error.message || 'Sorry! Cannot upgrade your plan',
              'Subscription'
            );
          }
        );
    } else {
      this._toaster.info('You have already used free trial.');
    }
  }

  /**
   * Creates the direct/free trial subscription
   * @param plan
   * @param isFree true/false
   */
  createSubscription(planData: any, isFree: boolean): void {
    this.user = window['user'];
    let reqObj: any = {
      status: 'active',
      isFreeTrial: isFree,
      createdBy: this.user._id,
      subscriptionId: this.user.subscription || null,
    };
    if (!isFree) {
      reqObj.rzpPlan = planData;
      reqObj.planType = planData.type;
      reqObj.planDuration = this.isMonthly ? 'monthly' : 'yearly';
    }
    // API call
    this._subscription
      .createSubscription(reqObj)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res: any) => {
          this.rzpSubData = res;
          // this._localStorage.setItem('user', res);
          // Direct subscription OR subscribe after free trial ends
          if (res.rzpSubId && !res.isFreeTrial && !res.isCancelled) {
            this._spinner.stopSpinner();
            this.checkoutOptions.subscription_id = res.rzpSubId;
            const rzp = new this._utils.nativeWindow.Razorpay(
              this.checkoutOptions
            );
            rzp.open();
            rzp.on('payment.failed', (response: any) => {
              // this._localStorage.setItem('paymentCaptured', false);
              this._toaster.error(
                `Payment failed - ${response.error.description}`,
                'Subscription'
              );
            });
          }
          // Subscribed during free trial
          else if (
            !res.rzpSubId &&
            res.isFreeTrial &&
            res.upcomingPlan &&
            !res.isCancelled
          ) {
            this._spinner.stopSpinner();
            this.rzpSubData.rzpSubId = !this.rzpSubData.rzpSubId
              ? res.upcomingPlan
              : this.rzpSubData.rzpSubId;
            this.checkoutOptions.subscription_id = res.upcomingPlan;
            const rzp = new this._utils.nativeWindow.Razorpay(
              this.checkoutOptions
            );
            rzp.open();
            rzp.on('payment.failed', (response: any) => {
              // this._localStorage.setItem('paymentCaptured', false);
              this._toaster.error(
                `Payment failed - ${response.error.description}`,
                'Subscription'
              );
            });
          }
          // Subscribed after the active subscription was cancelled
          else if (res.upcomingPlan && res.isCancelled) {
            this._spinner.stopSpinner();
            this.rzpSubData.rzpSubId = res.upcomingPlan;
            this.checkoutOptions.subscription_id = res.upcomingPlan;
            const rzp = new this._utils.nativeWindow.Razorpay(
              this.checkoutOptions
            );
            rzp.open();
            rzp.on('payment.failed', (response: any) => {
              // this._localStorage.setItem('paymentCaptured', false);
              this._toaster.error(
                `Payment failed - ${response.error.description}`,
                'Subscription'
              );
            });
          }
          // Only for free trial
          else if (res.isFreeTrial) {
            this._spinner.stopSpinner();
            !res.rzpPlanId ? delete res.rzpPlanId : null;
            !res.rzpSubId ? delete res.rzpSubId : null;
            if (res.subscription) {
              this._subscription
                .getSubscriptionDetails(res._id, res.subscription)
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe(
                  (data: any) => {
                    if (data) {
                      // reading the plan details form the server based on the user plan type.
                      let userSubsType =
                        !data.planType && data.isFreeTrial
                          ? 'pro'
                          : data.planType;
                      environment.appType === 'saas' &&
                        this.readPlanDetailsFromServer(userSubsType, res);
                      // this._toaster.success(
                      //   `Welcome aboard ${this._titleCasePipe.transform(this.user.firstName)}!`,
                      //   'Subscription'
                      // );
                    }
                  },
                  (err: HttpErrorResponse) => {
                    this._spinner.stopSpinner();
                    this._toaster.error(
                      err.error.message ||
                        'Unable to fetch subscription details',
                      'Subscription'
                    );
                  }
                );
            }
          } else {
            this._spinner.stopSpinner();
            this._toaster.error(
              'Sorry! Cannot create subscription/free trial. Something went wrong',
              'Subscription'
            );
          }
        },
        (err: HttpErrorResponse) => {
          this._spinner.stopSpinner();
          this._toaster.error(
            err.error.message || 'Sorry! Cannot create subscription',
            'Subscription'
          );
        }
      );
  }

  cancelSubscription(): void {
    swal({
      title: 'Are you sure?',
      text: `Cancellation will be effective at the end of your current billing period.`,
      icon: 'warning',
      dangerMode: true,
      buttons: {
        cancel: {
          text: 'Cancel',
          value: null,
          visible: true,
          className: '',
          closeModal: true,
        },
        confirm: {
          text: 'Yes, Confirm',
          visible: true,
          className: 'bg-danger',
          closeModal: true,
        },
      },
    }).then((isConfirm: any) => {
      if (isConfirm) {
        this._spinner.showSpinner();
        let reqObj = {
          isCancelImmediately: environment.isCancelImmediately,
          ...this.user,
        };
        this._subscription
          .cancelSubscription(reqObj)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe(
            (res: any) => {
              this._spinner.stopSpinner();
              if (res.isCancelled) {
                this.user.isCancelled = true;
                // this._localStorage.setItem('user', this.user);
                this._toaster.success(
                  'Your subscription has been cancelled successfully',
                  'Subscription'
                );
                // setTimeout(() => {
                //   this._router.navigate(['/login']);
                // }, 2100);
                this.signout();
                // this._router.navigate(['/login']);
              } else {
                this._spinner.stopSpinner();
                this._toaster.error(res.message, 'Subscription');
              }
            },
            (err: HttpErrorResponse) => {
              this._spinner.stopSpinner();
              this._toaster.error(
                err.error.message || 'Sorry! Cannot fetch plans',
                'Subscription'
              );
            }
          );
      }
    });
  }
  generatePlansArray(isMonthly: boolean): void {
    if (isMonthly) {
      this.monthlyPlans = [];
      this.rzpMonthlyPlans.forEach((plan: any) => {
        if (
          plan.item.name.toLowerCase() == this.userSubInfo.planName &&
          this.userSubInfo.status == 'active'
        ) {
          this.monthlyPlans.push({
            type: plan.item.name.toLowerCase(),
            price: plan.item.amount / 100,
            benefits: [...Object.values(plan.notes)],
            metaData: plan,
            status: 'active',
          });
        } else if (this.userSubInfo.planName == 'free trial') {
          this.freePlan['status'] = 'active';
        } else if (this.userSubInfo.planName == 'enterprise') {
          this.enterprisePlan['status'] = 'active';
        } else {
          this.monthlyPlans.push({
            type: plan.item.name.toLowerCase(),
            price: plan.item.amount / 100,
            benefits: [...Object.values(plan.notes)],
            metaData: plan,
            status: '',
          });
        }
      });

      this.monthlyPlans.push(this.freePlan);
      this.monthlyPlans.reverse();
      this.monthlyPlans.push(this.enterprisePlan);
    } else {
      this.yearlyPlans = [];
      this.rzpYearlyPlans.forEach((plan: any) => {
        if (
          plan.item.name.toLowerCase() == this.userSubInfo.planName &&
          this.userSubInfo.status == 'active'
        ) {
          this.yearlyPlans.push({
            type: plan.item.name.toLowerCase(),
            price: plan.item.amount / 100,
            benefits: [...Object.values(plan.notes)],
            metaData: plan,
            status: 'active',
          });
        } else if (this.userSubInfo.planName == 'free trial') {
          this.freePlan['status'] = 'active';
        } else if (this.userSubInfo.planName == 'enterprise') {
          this.enterprisePlan['status'] = 'active';
        } else {
          this.yearlyPlans.push({
            type: plan.item.name.toLowerCase(),
            price: plan.item.amount / 100,
            benefits: [...Object.values(plan.notes)],
            metaData: plan,
            status: '',
          });
        }
      });
      this.yearlyPlans.push(this.freePlan);
      this.yearlyPlans.reverse();
      this.yearlyPlans.push(this.enterprisePlan);
    }
    this._spinner.stopSpinner();
  }
  getDurationSymbol(plan: any): string {
    return this.isMonthly ? '/mo' : '/yr';
    // if (this.selectedPlan?.item.name === plan.type) {
    //   return this.selectedPlan?.period === 'yearly' ? '/yr' : '/mo';
    // } else {
    //   return this.isMonthly ? '/yr' : '/mo';
    // }
  }
  /**
   * Returns the heading css style as per the plan
   * @param planType
   * @returns
   */
  getTextStyle = (planType: string) =>
    this.stylesArray.find(({ type }) => type === planType).txtStyle;
  // getTextStyle(abc){
  // console.log("SubscriptionComponent -> getTextStyle -> abc", abc)

  // }

  getPlanName = (rzpPlanId: string) => PLANS.find(({ id }) => id === rzpPlanId);
  ngOnDestroy(): void {
    // throw new Error("Method not implemented.");
    this._socket.removeListener('RzpPaymentDone');
    this.unsubscribe$.next(false);
    this.unsubscribe$.unsubscribe();
  }
  upgradeUserPlan(planData: any, isFree: boolean): void {
    const { txtStyle, btnStyle, ...reqObj } = planData;
    reqObj.planDuration = this.isMonthly ? 'monthly' : 'yearly';
    reqObj.isUpgradeImmediately = environment.isUpgradeImmediately;
    reqObj.createdBy = this.user._id;
    reqObj.subId = this.user.subId.id;
    // API call
    if (!isFree) {
      this._subscription
        .upgradePlan(this.user._id, this.user.subId.id, reqObj)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          (res: any) => {
            // console.log('\n\n🚀 + res', res);
            if (res && res.subId?.upcomingPlans) {
              let userSubsType = res.subId.planType || null;
              this.readPlanDetailsFromServer(userSubsType, res.subId);

              this._toaster.success(
                'Plan is added to update cart successfully. Please check your email for details',
                'Subscription'
              );
              // this._router.navigate(['/login']);
              this.signout();
              // this._router.navigate(['/login']);
            } else {
              this._toaster.error(
                'Sorry! Cannot update your plan',
                'Subscription'
              );
            }
          },
          (err: HttpErrorResponse) => {
            this._spinner.stopSpinner();
            // console.log('\n\n🚀 + error', err);
            this._toaster.error(
              err.error.message || 'Sorry! Cannot update your plan',
              'Subscription'
            );
          }
        );
    } else {
      this._toaster.info('You have already used free trial.');
    }
  }
  signout() {
    const root = this;
    this._loginService.signout().subscribe((signout: any) => {
      window['user'] = undefined;
      this.user = undefined;
      // console.log('\n\n🚀 + signout', signout);
    });
  }
  doNothing() {}
  getFilteredName(planName: any) {
    return planName.replace('-', ' ');
  }
}
