<div
  [class]="bannerClass"
  id="sticky_bar"
  style="
    text-align: center;
    width: 100%;
    position: fixed;
    background-size: 10px 10px;
    z-index: 9999;
    top: 0;
    border-radius: 0;
  "
  role="alert"
  [hidden]="!bannerInfo.flag"
>
  <div class="row">
    <div
      class="col-md-11"
      style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis"
      title="{{ bannerInfo.msg }}"
    >
      <span *ngIf="iconType === 'info'">
        <img
          src="../../assets/images/info.png"
          style="height: auto; width: 1%"
        />&nbsp;&nbsp;
        {{ bannerInfo.msg }}
      </span>
      <span *ngIf="iconType === 'warn'">
        <img
          src="../../assets/images/warning.png"
          style="height: auto; width: 1%"
        />&nbsp;&nbsp;
        {{ bannerInfo.msg }}
      </span>
      <span *ngIf="iconType === 'error'">
        <img
          src="../../assets/images/cancel.png"
          style="height: auto; width: 1%"
        />&nbsp;&nbsp;
        {{ bannerInfo.msg }}
      </span>
      <span *ngIf="iconType === 'other'">
        <img
          src="../../assets/images/ellipsis.png"
          style="height: auto; width: 1%"
        />&nbsp;&nbsp;
        {{ bannerInfo.msg }}
      </span>
    </div>
    <div class="col">
      <ng-container
        *ngIf="bannerInfo.data?.action?.isSilent; else elseTemplate"
      >
        <mat-icon
          class="material-icons-outlined hand"
          (click)="toggleTriggerMode()"
          >volume_off</mat-icon
        >
      </ng-container>
      <ng-template #elseTemplate>
        <mat-icon
          class="material-icons-outlined hand"
          (click)="toggleTriggerMode()"
        >
          volume_up</mat-icon
        >
      </ng-template>

      <button
        type="button"
        class="close btn-close pull-right"
        data-dismiss="alert"
        aria-label="Close"
        (click)="closeBanner()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>
</div>
<div class="wrapper" [style.top.px]="bannerInfo.flag ? 48 : 0">
  <!-- top navbar-->
  <app-header class="topnavbar-wrapper iotsense_font"></app-header>
  <!-- sidebar-->
  <app-sidebar class="aside-container"></app-sidebar>
  <!-- offsidebar-->
  <app-offsidebar class="offsidebar iotsense_font"></app-offsidebar>
  <!-- Notification panel-->
  <app-notifications
    class="offsidebar iotsense_font"
    [style.top.px]="bannerInfo.flag ? 44 : 0"
  ></app-notifications>
  <!-- Main section-->

  <section class="section-container">
    <!-- Page content-->
    <div class="content-wrapper iotsense_font">
      <!-- {{ bannerInfo.msg }} -->

      <router-outlet></router-outlet>
    </div>
  </section>
  <!-- Page footer-->
  <footer
    style="font-size: smaller"
    class="footer-container iotsense_font"
    app-footer
  ></footer>
</div>
