import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from "../../../../environments/environment";
import * as io from "socket.io-client";
@Injectable({
  providedIn: 'root'
})
export class ConnectivityService {
  socket: any;
  private url = "";
  constructor() {if (environment.apiUrl) {
    this.url = environment.apiUrl
  };
  this.socket = io(this.url);
 }
listen(eventName: string) {
    return new Observable((subscriber) => {
      this.socket.on(eventName, (data) => {
        subscriber.next(data);
      });
    });
  }
  emit(eventName: string, data: any) {
    this.socket.emit(eventName, data);
  }
}
