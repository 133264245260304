import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/observable/forkJoin';
import { environment } from '../../../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ThingService {
  private url = '';
  constructor(private http: HttpClient) {
    if (environment.apiUrl) {
      this.url = environment.apiUrl;
    }
  }
  getThingsList() {
    return this.http
      .get(this.url + '/api/things', { withCredentials: true })
      .pipe(
        map((list) => {
          return list;
        })
      );
  }
  getModbusRequests() {
    return this.http
      .get(this.url + '/api/modbus/requests', { withCredentials: true })
      .pipe(
        map((list) => {
          return list;
        })
      );
  }
  getRTUNetworks() {
    return this.http
      .get(this.url + '/api/modbus/rtunetworks', { withCredentials: true })
      .pipe(
        map((list) => {
          return list;
        })
      );
  }
  getModbusSlaves() {
    return this.http
      .get(this.url + '/api/modbus/slaves', { withCredentials: true })
      .pipe(
        map((list) => {
          return list;
        })
      );
  }
  getSyncOptions() {
    return this.http
      .get(this.url + '/api/sync/options', { withCredentials: true })
      .pipe(
        map((list) => {
          return list;
        })
      );
  }
  getSnmpConfigs() {
    return this.http
      .get(this.url + '/api/snmp/configs', { withCredentials: true })
      .pipe(
        map((list) => {
          return list;
        })
      );
  }
  getOpcSessions() {
    return this.http
      .get(this.url + '/api/opc/sessions', { withCredentials: true })
      .pipe(
        map((list) => {
          return list;
        })
      );
  }
  getOpcMonitors() {
    return this.http
      .get(this.url + '/api/opc/monitors', { withCredentials: true })
      .pipe(
        map((list) => {
          return list;
        })
      );
  }

  getThingsView(thingId) {
    return this.http
      .get(this.url + '/api/things/' + thingId, { withCredentials: true })
      .pipe(
        map((list) => {
          return list;
        })
      );
  }
  getThingDatastreams(thingId) {
    return this.http
      .get(this.url + '/api/dump/' + thingId, { withCredentials: true })
      .pipe(
        map((list) => {
          return list;
        })
      );
  }
  getThingTriggerLogs(thingId) {
    return this.http
      .get(this.url + '/api/triggerlogs/things/' + thingId, {
        withCredentials: true,
      })
      .pipe(
        map((list) => {
          return list;
        })
      );
  }
  getTriggersById(triggerId) {
    return this.http
      .get(this.url + '/api/triggers/' + triggerId, { withCredentials: true })
      .pipe(
        map((list) => {
          return list;
        })
      );
  }

  createThing(deviceData) {
    return this.http
      .post(this.url + '/api/things', deviceData, { withCredentials: true })
      .pipe(
        map((list) => {
          return list;
        })
      );
  }

  getUsersList() {
    return this.http
      .get(this.url + '/api/users/all', { withCredentials: true })
      .pipe(
        map((list) => {
          return list;
        })
      );
  }
  getOwner(userId) {
    return this.http
      .get(this.url + '/api/users/' + userId, { withCredentials: true })
      .pipe(
        map((list) => {
          return list;
        })
      );
  }
  editThing(thing) {
    return this.http
      .put(this.url + '/api/things/' + thing._id, thing, {
        withCredentials: true,
      })
      .pipe(
        map((list) => {
          return list;
        })
      );
  }
  removeThing(thingId) {
    return this.http
      .delete(this.url + '/api/things/' + thingId, { withCredentials: true })
      .pipe(
        map((list) => {
          return list;
        })
      );
  }
  regenerateRead(thing) {
    return this.http
      .put(
        this.url + '/api/things/' + thing._id + '/tokens/regenerate-read',
        thing,
        { withCredentials: true }
      )
      .pipe(
        map((list) => {
          return list;
        })
      );
  }
  regenerateWrite(thing) {
    return this.http
      .put(
        this.url + '/api/things/' + thing._id + '/tokens/regenerate-write',
        thing,
        { withCredentials: true }
      )
      .pipe(
        map((list) => {
          return list;
        })
      );
  }
  syncThingData(id) {
    return this.http
      .get(this.url + '/api/things/' + id + '/sync', { withCredentials: true })
      .pipe(
        map((list) => {
          // console.log('TCL: syncThingData -> list', list);
          return list;
        })
      );
  }

  getSnmpTraps() {
    return this.http
      .get(this.url + '/api/snmp/traps', { withCredentials: true })
      .pipe(
        map((list) => {
          return list;
        })
      );
  }
  getDatastreamById(id) {
    // console.log('TCL: getDatastreamById -> id', id);
    return this.http
      .post(this.url + '/api/dump/ids', id, { withCredentials: true })
      .pipe(
        map((list) => {
          return list;
        })
      );
  }

  getmtconnectconfigList() {
    return this.http
      .get(this.url + '/api/MTConnect/configs', { withCredentials: true })
      .pipe(
        map((list) => {
          return list;
        })
      );
  }

  getmtconnectrequestList() {
    return this.http
      .get(this.url + '/api/MTConnect/request', { withCredentials: true })
      .pipe(
        map((list) => {
          return list;
        })
      );
  }
  getDeviceIdCreation() {
    return this.http
      .get(this.url + '/api/settings/deviceIdCreation', {
        withCredentials: true,
      })
      .pipe(
        map((list) => {
          return list;
        })
      );
  }
}
