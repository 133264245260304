import { Component, EventEmitter, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { TriggerService } from '../routes/smart-action/services/trigger.service';
import { SharedService } from '../shared.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit {
  showBanner: boolean;
  bannerInfo: any = {};
  bannerClass: string;
  iconType;

  constructor(
    public ss: SharedService,
    private ts: TriggerService,
    private _toaster: ToastrService
  ) {}

  ngOnInit() {
    this.ss.showBanner.subscribe((flag) => {
      this.bannerInfo.flag = flag;
    });
    this.ss.updateBannerInfo.subscribe((bannerInfo1: any) => {
      this.iconType = bannerInfo1.type;
      this.bannerInfo = bannerInfo1;
      switch (bannerInfo1.type) {
        case 'info':
          this.bannerClass = 'alert blue-panel';
          break;
        case 'warn':
          this.bannerClass = 'alert warn-panel';
          break;
        case 'error':
          this.bannerClass = 'alert danger-panel';
          break;
        default:
          this.bannerClass = 'alert green-panel';
      }
    });
  }
  closeBanner() {
    this.bannerInfo = {};
    this.ss.hideNotificationBanner();
  }
  /**
   * To update silent mode settings in action for a particular trigger
   */
  toggleTriggerMode() {
    let payload: any = this.bannerInfo.data;
    payload.action['isSilent'] = !payload.action['isSilent'];
    this.ts.updateTrigger(payload, this.bannerInfo.data._id).subscribe(
      (data) => {
        this.bannerInfo.data.action.isSilent = payload.action['isSilent'];
        this._toaster['info'](
          payload.action['isSilent']
            ? 'Future banner notifications are muted for trigger ' +
                payload.name +
                '.'
            : 'Future banner notifications are unmuted for trigger ' +
                payload.name +
                '.',
          'Banner'
        );
      },
      (err) => {}
    );
  }
}
