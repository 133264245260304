import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  private fromBackup = new BehaviorSubject(false);
  private backupCompleted = new BehaviorSubject(false);

  public updateBannerInfo = new EventEmitter();
  public closeEvent = new EventEmitter();
  audio = { data: undefined, tune: undefined };
  public showBanner: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  bannerPriority = {
    error: 4,
    warn: 3,
    info: 2,
    other: 1,
  };
  constructor() {}
  getFromBackupFlag(): Observable<any> {
    return this.fromBackup.asObservable();
  }

  getBackupCompletedFlag(): Observable<any> {
    return this.backupCompleted.asObservable();
  }

  toggleFromBackupFlag(flag: boolean) {
    // console.log('SharedService ~ CHANGING', flag);
    this.fromBackup.next(flag);
  }
  toggleBackupCompletedFlag(flag: boolean) {
    this.backupCompleted.next(flag);
  }
  updateBanner(info: any) {
    if (!this.audio.tune) {
      this.updateBannerInfo.emit(info);
      this.audio.data = info;
    }
  }
  showNotificationBanner(audio, bType, newdata, notifyTrigger: any) {
    newdata['data'] = notifyTrigger;
    if (audio && this.audio.data.type) {
      if (this.audio.tune) {
        let condition = this.checkPriority(bType);
        if (condition) {
          this.updateBannerInfo.emit(newdata);
          this.audio.tune.pause();
          this.audio.tune.currentTime = 0;
          this.audio.data = newdata;
          this.showBanner.next(true);

          let audioPath = '../../assets/tunes/' + audio;

          this.audio.tune = new Audio(audioPath);
          this.audio.tune.load();
          this.audio.tune.play();
          this.audio.tune.onended = () => {
            // console.log('audio ended');
            this.audio.tune.pause();
            this.audio.tune.currentTime = 0;
            this.audio.tune = undefined;
          };
        }
      } else {
        this.showBanner.next(true);

        let audioPath = '../../assets/tunes/' + audio;

        this.audio.tune = new Audio(audioPath);
        this.audio.tune.load();
        this.audio.tune.play();
        this.audio.tune.onended = () => {
          // console.log('audio ended');
          this.audio.tune.pause();
          this.audio.tune.currentTime = 0;
          this.audio.tune = undefined;
        };
      }
    }
  }
  hideNotificationBanner() {
    if (this.audio.tune) {
      this.audio.tune.pause();
      this.audio.tune.currentTime = 0;
      this.audio.tune = undefined;
    }
    this.showBanner.next(false);
  }
  isPlaying = (audElem) => {
    return !audElem.paused;
  };
  checkPriority(bType) {
    let oldType = this.bannerPriority[this.audio.data.type];
    let newType = this.bannerPriority[bType];
    return newType >= oldType;
  }
}
