<!-- START Top Navbar-->

<nav class="navbar topnavbar" role="navigation">
  <!-- START navbar header-->
  <div class="navbar-header">
    <a
      class="navbar-brand"
      [routerLink]="['/home']"
      style="box-shadow: inset 0px 0px 4px rgba(0, 0, 0, 0.1)"
    >
      <div class="brand-logo">
        <img class="img-fluid" src="assets/img/logo2.svg" alt="App Logo" />
      </div>
      <div class="brand-logo-collapsed">
        <img
          class="img-fluid"
          src="assets/img/logo-single2.svg"
          alt="App Logo"
        />
      </div>
    </a>
  </div>
  <!-- END navbar header-->
  <!-- START Left navbar-->
  <ul class="navbar-nav mr-auto flex-row">
    <li class="nav-item">
      <!-- Button used to collapse the left sidebar. Only visible on tablet and desktops-->
      <a
        class="nav-link d-none d-md-block d-lg-block d-xl-block"
        trigger-resize=""
        (click)="toggleCollapsedSideabar()"
        *ngIf="!isCollapsedText()"
      >
        <em
          *ngIf="sidebarCollapse == true"
          class="fas fa-angle-double-right fa-lg"
        ></em>
        <em
          *ngIf="sidebarCollapse == false"
          class="fas fa-angle-double-left fa-lg"
        ></em>
      </a>

      <!-- Button to show/hide the sidebar on mobile. Visible on mobile only.-->
      <a
        class="nav-link sidebar-toggle d-md-none"
        (click)="
          settings.toggleLayoutSetting('asideToggled'); $event.stopPropagation()
        "
      >
        <em class="fas fa-chevron-circle-right"></em>
      </a>
    </li>
    <!-- START User avatar toggle-->
    <!-- <li class="nav-item d-none d-md-block">
            <a  class="nav-link"  (click)="toggleUserBlock($event)">
                <em class="icon-user"></em>
            </a>
        </li>  -->
    <!-- END User avatar toggle-->
    <!-- START lock screen-->
    <!-- <li class="nav-item d-none d-md-block">
            <a class="nav-link" title="Lock screen" [routerLink]="'/lock'">
                <em class="icon-lock"></em>
            </a>
        </li> -->
    <!-- END lock screen-->
  </ul>
  <!-- END Left navbar-->
  <!-- START Right Navbar-->
  <ul class="navbar-nav flex-row mr-1">
    <!-- Search icon-->
    <!-- <li class="nav-item">
            <a class="nav-link" (click)="openNavSearch($event)">
                <em class="icon-magnifier"></em>
            </a>
        </li>
        Edit Profile and Change Password-->
    <link
      href="https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined"
      rel="stylesheet"
    />
    <li
      *ngIf="user?.is_Invited && diffDays <= 5"
      class="nav-item dropdown dropdown-list"
      style="cursor: pointer"
    >
      <a class="nav-link" title="{{ freeTrialMsg }}">
        <mat-icon
          fontSet="material-icons-outlined"
          style="font-size: 19px !important"
          >admin_panel_settings
        </mat-icon>
      </a>
    </li>
    <!-- <blink
      [active]="true"
      *ngIf="user?.is_Invited && user?.subId?.planType == 'Free'"
      >Start editing to see some magic happen :)</blink
    > -->
    <li
      *ngIf="!user?.is_Invited"
      class="nav-item dropdown dropdown-list"
      style="cursor: pointer"
    >
      <a
        class="nav-link"
        (click)="
          toggleNotificationPanel(); $event.stopPropagation(); someMethod()
        "
      >
        <i class="bi bi-bell" style="font-size: 17px !important"></i>
        <span
          [hidden]="this.notificationServiceService.data?.length == 0"
          class="badge badge-danger"
          >{{ this.notificationServiceService.data?.length }}</span
        >
      </a>
    </li>
    <li
      *ngIf="user?.is_Invited && user?.subId?.planType == 'Free'"
      class="nav-item d-none d-md-block"
      style="cursor: pointer"
    >
      <a
        class="nav-link pointer"
        [matMenuTriggerFor]="guide"
        matTooltip="Help Guide"
        matTooltipPosition="below"
        (click)="LaunchGuideById()"
      >
        <blink [active]="true">
          <i
            class="bi bi-question-circle"
            style="font-size: 19px !important"
          ></i>
        </blink>
      </a>
      <mat-menu style="height: 40px" #guide="matMenu">
        <a
          class="nav-link pointer"
          mat-menu-item
          *ngFor="let item of AdminGuides"
          style="background-color: transparent"
        >
          <span>
            {{ item.help }}
            &nbsp;<i
              *ngIf="!(item.help == 'Coming soon')"
              class="bi bi-info-circle"
              matTooltip="{{ item.desc }}"
              matTooltipPosition="below"
            ></i
          ></span>
        </a>
      </mat-menu>
    </li>
    <li class="nav-item d-none d-md-block" style="cursor: pointer">
      <!-- Button used to collapse the left sidebar. Only visible on tablet and desktops -->
      <a class="nav-link pointer" [matMenuTriggerFor]="menu">
        <i class="bi bi-person" style="font-size: 19px !important"></i>
        &nbsp;
        <span class="ng-binding">{{ user.displayName }}</span>
      </a>
      <mat-menu style="height: 40px" #menu="matMenu">
        <a
          class="nav-link pointer"
          [hidden]="role == 'operator'"
          mat-menu-item
          [routerLink]="'/usersettings/profile'"
          style="background-color: transparent"
        >
          <i class="bi bi-pencil"></i>
          &nbsp;
          <span>Edit Profile</span>
        </a>
        <!-- <a
          class="nav-link pointer"
          [hidden]="role == 'operator'"
          mat-menu-item
          [routerLink]="'/usersettings/password'"
          style="background-color: transparent"
        >
          <i class="bi bi-eye"></i>
          &nbsp;
          <span>Change Password</span>
        </a> -->
        <a
          class="nav-link pointer"
          [hidden]="user?.is_Invited == false"
          mat-menu-item
          [routerLink]="'/subscription'"
          style="background-color: transparent"
        >
          <!-- <i class="bi bi-cash-coin"></i> -->
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-cash-coin"
            viewBox="0 0 16 16"
          >
            <path
              fill-rule="evenodd"
              d="M11 15a4 4 0 1 0 0-8 4 4 0 0 0 0 8zm5-4a5 5 0 1 1-10 0 5 5 0 0 1 10 0z"
            />
            <path
              d="M9.438 11.944c.047.596.518 1.06 1.363 1.116v.44h.375v-.443c.875-.061 1.386-.529 1.386-1.207 0-.618-.39-.936-1.09-1.1l-.296-.07v-1.2c.376.043.614.248.671.532h.658c-.047-.575-.54-1.024-1.329-1.073V8.5h-.375v.45c-.747.073-1.255.522-1.255 1.158 0 .562.378.92 1.007 1.066l.248.061v1.272c-.384-.058-.639-.27-.696-.563h-.668zm1.36-1.354c-.369-.085-.569-.26-.569-.522 0-.294.216-.514.572-.578v1.1h-.003zm.432.746c.449.104.655.272.655.569 0 .339-.257.571-.709.614v-1.195l.054.012z"
            />
            <path
              d="M1 0a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h4.083c.058-.344.145-.678.258-1H3a2 2 0 0 0-2-2V3a2 2 0 0 0 2-2h10a2 2 0 0 0 2 2v3.528c.38.34.717.728 1 1.154V1a1 1 0 0 0-1-1H1z"
            />
            <path
              d="M9.998 5.083 10 5a2 2 0 1 0-3.132 1.65 5.982 5.982 0 0 1 3.13-1.567z"
            />
          </svg>
          &nbsp;
          <span>Subscription Plan</span>
        </a>
        <hr
          style="margin-top: 0rem; margin-bottom: 0rem"
          [hidden]="role == 'operator'"
        />
        <a
          class="nav-link pointer"
          mat-menu-item
          href="/api/auth/signout"
          target="_self"
          style="background-color: transparent"
        >
          <i class="bi bi-box-arrow-right"></i>
          &nbsp;
          <span>Signout</span>
        </a>
      </mat-menu>
    </li>

    <!-- END Offsidebar menu-->
  </ul>
  <!-- END Right Navbar-->

  <!-- <app-navsearch [visible]="getNavSearchVisible()" (onclose)="setNavSearchVisible(false)"></app-navsearch> -->
</nav>

<div *ngIf="loaderService.isLoading | async" class="loader-container">
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>

<!-- class="alert alert-warning alert-dismissible fade show" -->

<!-- END Top Navbar-->
<style>
  ::ng-deep .mat-menu-panel {
    padding: 0 10px !important;
    width: 100% !important;
  }
</style>
