import {
  Component,
  OnInit,
  ViewChild,
  Injector,
  ViewEncapsulation,
  NgZone,
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
const screenfull = require('screenfull');
import { UserblockService } from '../sidebar/userblock/userblock.service';
import { SettingsService } from '../../core/settings/settings.service';
import { MenuService } from '../../core/menu/menu.service';
import { NotificationServiceService } from '../notification-service.service';
import { CookieService } from 'ngx-cookie-service';
import { MatMenuTrigger } from '@angular/material/menu';
import { LoginService } from '../../routes/pages/login/login.service';
import { LoaderService } from '../loader/loader.service';
import { SharedService } from 'src/app/shared.service';
import { UsersettingsService } from '../../routes/user-settings/usersettings.service';

declare var $: any;
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent implements OnInit {
  navCollapsed = true; // for horizontal layout
  menuItems = []; // for horizontal layout
  router: Router;
  user;
  isNavSearchVisible: boolean;
  sidebarCollapse = false;
  temp = false;
  @ViewChild('fsbutton', { static: true }) fsbutton; // the fullscreen button
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  role: any;
  freeTrialMsg: any;
  diffDays: any;
  Pendo: any;
  AdminGuides = [];
  currentRoute: any;
  bannerMsg: any;
  bannerMsgType: any;
  showBanner: boolean;
  bannerInfo: any = {};
  bannerClass: string;
  public data = {};
  constructor(
    public menu: MenuService,
    public userblockService: UserblockService,
    public settings: SettingsService,
    public injector: Injector,
    public notificationServiceService: NotificationServiceService,
    private cookieService: CookieService,
    private _loginService: LoginService,
    private ngZone: NgZone,
    private _router: Router,
    private route: ActivatedRoute,
    public loaderService: LoaderService,
    public ss: SharedService,
    public UsersettingsService: UsersettingsService
  ) {
    // show only a few items on demo
    // this.menuItems = menu.getMenu().slice(0, 4); // for horizontal layout
    this.UsersettingsService.myMethod$.subscribe((data) => {
      this.data = data; // And he have data here too!
      this.user = this.data;
      this.user.displayName = `${this.user.firstName} ${this.user.lastName}`;
    });
  }

  ngOnInit() {
    this.user = window['user'];

    if (this.user?.is_Invited) {
      this._loginService
        .getUserSubscriptionInfo(this.user._id)
        .subscribe((data: any) => {
          const date1: any = new Date();
          const date2: any = new Date(data.planEnd);
          const diffTime = Math.abs(date2 - date1);
          this.diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
          this.freeTrialMsg =
            this.diffDays > 1
              ? 'Your free trial ends in ' + this.diffDays + ' days.'
              : 'Your free trial ends in ' + this.diffDays + ' day.';
        });
    }
    // this.user = JSON.parse(this.user);
    this.role = this.user.roles[0];
    // console.log("TCL: HeaderComponent -> ngOnInit -> this.role", this.role)
    this.isNavSearchVisible = false;
    var ua = window.navigator.userAgent;
    if (ua.indexOf('MSIE ') > 0 || !!ua.match(/Trident.*rv\:11\./)) {
      // Not supported under IE
      this.fsbutton.nativeElement.style.display = 'none';
    }

    // Switch fullscreen icon indicator
    const el = this.fsbutton.nativeElement.firstElementChild;
    screenfull.on('change', () => {
      if (el)
        el.className = screenfull.isFullscreen
          ? 'fa fa-compress'
          : 'fa fa-expand';
    });

    this.router = this.injector.get(Router);

    // Autoclose navbar on mobile when route change
    this.router.events.subscribe((val) => {
      // scroll view to top
      window.scrollTo(0, 0);
      // close collapse menu
      this.navCollapsed = true;
    });
  }
  someMethod() {
    this.trigger.closeMenu(); // <-- put this in your dialog open method
  }
  toggleUserBlock(event) {
    event.preventDefault();
    this.userblockService.toggleVisibility();
  }

  openNavSearch(event) {
    event.preventDefault();
    event.stopPropagation();
    this.setNavSearchVisible(true);
  }

  setNavSearchVisible(stat: boolean) {
    // console.log(stat);
    this.isNavSearchVisible = stat;
  }

  getNavSearchVisible() {
    return this.isNavSearchVisible;
  }

  toggleOffsidebar() {
    this.settings.toggleLayoutSetting('offsidebarOpen');
  }

  toggleNotificationPanel() {
    this.settings.toggleLayoutSetting('openNotifications');
  }

  toggleCollapsedSideabar() {
    this.sidebarCollapse = this.settings.toggleLayoutSetting('isCollapsed');
  }

  isCollapsedText() {
    return this.settings.getLayoutSetting('isCollapsedText');
  }

  toggleFullScreen(event) {
    if (screenfull.enabled) {
      screenfull.toggle();
    }
  }
  signout() {
    this._loginService.signout().subscribe(
      (data) => {
        // console.log(' signing out');
        this.router.navigate(['/login']);
      },
      (error) => {
        console.log('error');
      }
    );
  }
  LaunchGuideById() {
    this.AdminGuides = [];

    let routeURL = this._router.url.startsWith(
      '/smart-action/triggers/edittrigger/'
    )
      ? '/smart-action/triggers/edittrigger/*'
      : this._router.url;
    switch (`${routeURL}`) {
      case '/datamanagement/exportdata':
        let HelpExportData: any = {
          desc: 'Will guide you through steps for exporting demo data.',
          help: 'Export Data',
        };
        this.AdminGuides.push(HelpExportData);

        break;

      case '/datamanagement/datastreams':
        let HelpDatastream: any = {
          desc: 'Will guide you to view datastream data.',
          help: 'Datastream',
        };
        this.AdminGuides.push(HelpDatastream);

        break;
      case '/data-table':
        let HelpDatatable: any = {
          desc: 'Will guide you about adding data in datatable.',
          help: 'Datatable',
        };
        this.AdminGuides.push(HelpDatatable);

        break;
      case '/settings/system':
        let HelpGenerateDemoData: any = {
          help: 'Generate Demo Data',
          desc: 'Will guide you through steps to generate demo data.',
        };
        this.AdminGuides.push(HelpGenerateDemoData);
        break;

      case '/smart-action/triggers/createtrigger':
        let HelpTrigger: any = {
          help: 'Trigger Condition',
          desc: 'Will guide you to configure trigger condition.',
        };
        this.AdminGuides.push(HelpTrigger);
        break;
      case '/smart-action/triggers/edittrigger/*':
        let HelpTriggerEdit: any = {
          help: 'Trigger Condition',
          desc: 'Will guide you to configure trigger condition.',
        };
        this.AdminGuides.push(HelpTriggerEdit);
        break;

      case '/home':
        let HomeGuide: any = {
          help: 'Admin Modules',
          desc: 'Will walk you through and provide brief information about Admin modules.',
        };
        this.AdminGuides.push(HomeGuide);
        break;

      default:
        let NoGuideAvailable: any = {
          help: 'Coming soon',
        };
        this.AdminGuides.push(NoGuideAvailable);
        break;
    }
    // console.log('\n\n🚀 + this.AdminGuides', this.AdminGuides);
  }
  closeBanner() {
    this.bannerInfo = {};
  }
}
