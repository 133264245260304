<section class="vh-100">
  <div class="container py-5 h-100">
    <div
      class="row d-flex justify-content-center align-items-center h-100"
      style="flex-direction: column"
    >
      <div class="col-12 col-md-8 col-lg-6 col-xl-5">
        <div style="text-align: center">
          <img
            class="img-fluid"
            src="../../../../assets/img/logo2.svg"
            alt="App Logo"
          />
          <h3
            class="mb-4 mt-1"
            style="
              font-size: 24px;
              font-weight: 500;
              color: #463b3b;
              letter-spacing: 0.3px;
            "
          >
            Hi, Welcome back
          </h3>
        </div>

        <form
          [formGroup]="valForm"
          role="form"
          name="loginForm"
          novalidate=""
          (submit)="submitForm($event, valForm.value)"
        >
          <div class="col-md-12 groupp">
            <mat-form-field
              appearance="outline"
              style="display: block; margin-bottom: 12px"
            >
              <mat-label>Username</mat-label>
              <input
                matInput
                id="exampleInputEmail1"
                type="text"
                name="username"
                placeholder="Username"
                autocomplete="off"
                formControlName="username"
                oninput="this.value = this.value.replace(/[^A-Za-z0-9\S ]|^ /g,'')"
                required=""
              />
              <mat-error *ngIf="valForm.get('username').hasError('required')"
                >Username is required</mat-error
              >
            </mat-form-field>
          </div>
          <div class="col-md-12 groupp">
            <mat-form-field
              appearance="outline"
              style="display: block; margin-bottom: 2px"
            >
              <mat-label>Password</mat-label>
              <input
                matInput
                id="exampleInputPassword1"
                [type]="hide ? 'password' : 'text'"
                name="password"
                placeholder="Password"
                formControlName="password"
                required=""
              />
              <mat-icon id="passCursor" matSuffix (click)="hide = !hide"
                >{{ hide ? "visibility_off" : "visibility" }}
              </mat-icon>
              <mat-error *ngIf="valForm.get('password').hasError('required')"
                >Password is required</mat-error
              >
            </mat-form-field>
          </div>
          <div class="form-check">
            <a href="page/password/forgot">Forgot Password?</a>
          </div>
          <div class="col-md-12 col-sm-12 mt-3">
            <button
              style="width: 100%"
              class="btn btn-primary btn-lg material-cust-large-button"
              type="submit"
            >
              Login
            </button>
          </div>
        </form>
        <!-- <div class="col-md-12 mt-3" style="text-align:center">
          Don't have an account? <a routerLink="/signup">Sign up</a>
        </div> -->
        <div class="container-fluid">
          <div class="col-md-12 mt-3" style="display: inline">
            <!-- Copyright -->
            <div
              class="footer-copyright text-center py-3"
              style="font-size: 11px"
            >
              &copy; {{ settings.getAppSetting("year") }} -
              {{ settings.getAppSetting("name") }} WINJIT INC | ALL RIGHTS
              RESERVED. <br />
              POWERED BY
              <a target="_blank" href="https://www.winjit.com"
                >WINJIT TECHNOLOGIES</a
              >.
            </div>
            <!-- Copyright -->
          </div>
        </div>
      </div>
    </div>
    <!-- <div style="text-align: center; padding-top: 10px;font-size: 13px;font-family: 'iotsense_font';"> COPYRIGHT <span>&copy; {{settings.getAppSetting('year')}} - {{ settings.getAppSetting('name') }}</span> WINJIT INC | ALL RIGHTS RESERVED. | POWERED BY WINJIT TECHNOLOGIES.</div> -->
  </div>
</section>
